import './header.scss';
import LoginAndOutButton from '../loginAndOutButton/loginAndOutButton';
import { Link } from 'react-router-dom';
// import { ReactComponent as UserCircleSVG } from '../../Assets/SVGs/user.svg';
import { ReactComponent as MenuSVG } from '../../Assets/SVGs/menu.svg';
import React, { useState, CSSProperties, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../../Services/userServices';
// import { useUserData } from '../../Hooks/userHooks';
// Types
import { AllRoutes } from '../../App';

/*

Todo:

- use useUserProfile hook instead
  - make sure isExpert a prop on UserProfile

*/

function Header() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [isExpertUser, setExpertUser] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(true);
  const [userPicture, setUserPicture] = useState<string>('');
  const [userName, setUsername] = useState<string>('');
  // If user directs to this url, check a few things
  useEffect(() => {
    setBusy(true);
    if (!isLoading && isAuthenticated) {
      getUserData(user?.['sub'] || '').then((response) => {
        console.log('header get user response: ', response);
        if (response?.status == 200) {
          setUserPicture(user?.['picture'] || '');
          response.data?.isExpert ? setExpertUser(true) : setExpertUser(false);
          setUsername(response.data!.userName);
          setBusy(false);
        } else {
          setBusy(false);
        }
      });
    } else {
      setBusy(false);
    }
  }, [user]);

  // Toggle Side Menu
  function toggleOffCanvas(): void {
    setOpen(!open);
  }

  const styles: CSSProperties = {
    transform: open ? 'translateX(0%)' : 'translateX(-100%)',
    visibility: 'visible',
  };

  return (
    <>
      <div className="offcanvas offcanvas-start" style={styles} id="demo">
        <div className="offcanvas-header">
          <button
            onClick={toggleOffCanvas}
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="offcanvas-menu-item">
            <Link to={AllRoutes.SEARCH}>Browse</Link>
          </div>
          <div className="offcanvas-menu-item">
            <Link to={AllRoutes.GENERIC_ABOUT}>About</Link>
          </div>
          <div className="offcanvas-menu-item">
            {isExpertUser ? (
              <Link
                className="btn btn-outline-dark"
                to={`/expertProfile/${userName}`}
              >
                Profile
              </Link>
            ) : (
              <Link className="btn btn-outline-dark" to={AllRoutes.ABOUT}>
                Become An Expert
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <div className="row">
          <div className="header-container">
            <div className="header-contents">
              <div onClick={toggleOffCanvas} className="hamburger-menu">
                <MenuSVG />
              </div>
              <div onClick={() => navigate('/')} className="col-5">
                <div className="header-logo"></div>
              </div>
              <div className="col-3 header-links">
                <div className="row">
                  <Link to={AllRoutes.SEARCH} className="col-sm-3 header-link">
                    Browse
                  </Link>
                  <span className="separator">|</span>
                  <Link
                    to={AllRoutes.GENERIC_ABOUT}
                    className="col-sm-3 header-link"
                  >
                    About
                  </Link>
                </div>
              </div>
              {isLoading || busy ? (
                <>
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </>
              ) : (
                <div className="col-8 sign-in-buttons">
                  <div className="expert-sign-up">
                    {isExpertUser ? (
                      <Link
                        className="btn btn-outline-dark"
                        to={`/expertProfile/${userName}`}
                      >
                        Profile
                      </Link>
                    ) : (
                      <Link
                        className="btn btn-outline-dark"
                        to={AllRoutes.ABOUT}
                      >
                        Become An Expert
                      </Link>
                    )}
                  </div>
                  <div className="log-in">
                    {isAuthenticated ? (
                      <div className="avatar-icon">
                        <div className="userImage">
                          <button
                            className="btn btn-outline-dark btn-sm"
                            data-bs-toggle="dropdown"
                            data-bs-target="#dropdown-menu"
                          >
                            <span>
                              <MenuSVG />
                            </span>
                            <img
                              className="user-picture"
                              src={userPicture}
                              height="32px"
                              width="32px"
                            ></img>
                          </button>
                          <ul className="dropdown-menu" id="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#">
                                <LoginAndOutButton isLoginButton={false} />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <>
                        <LoginAndOutButton isLoginButton={true} />{' '}
                        {/* <UserCircleSVG /> */}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
