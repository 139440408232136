import { APIHookReturn } from './useAPI';

declare const REST_METHODS: readonly ['get', 'post', 'put', 'delete'];
declare const ONBOARDING_STEP: readonly [
  'USERNAME',
  'PHONENUMBER',
  'BIO',
  'CREDIBILITY',
  'PHOTO',
  'COMPLETE'
];

// --------------------------- NEVER REFERENCE VARIABLES ABOVE THIS LINE ---------------------------

// These types are generated from the readonly variables above. Think of them as an 'enum' alternative

export declare type RestMethod = typeof REST_METHODS[number];
export declare type OnboardingStep = typeof ONBOARDING_STEP[number];

// Hook Types

export type GetHook<TData> = (arg0: string) => APIHookReturn<TData>;
export enum TwilioResponses {
  VALID = 'Valid',
  SENT = 'Sent',
}
