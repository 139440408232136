import React from 'react';
import { ReactComponent as StarEmptySVG } from '../../Assets/SVGs/star-empty.svg';
import { ReactComponent as StarFullSVG } from '../../Assets/SVGs/full-star.svg';
import { ReactComponent as StarHalfSVG } from '../../Assets/SVGs/half-star.svg';

import './starReview.scss';

export type Props = {
  reviewScore: number;
};

function StarReview(props: Props) {
  const reviews = [];

  let i = 1;
  let score = props.reviewScore;
  while (i < 6) {
    if (score <= 0) {
      reviews.push(
        <div key={i} className="review">
          <StarEmptySVG />
        </div>
      );
    } else if (score > 0 && score < 1) {
      reviews.push(
        <div key={i} className="review">
          <StarHalfSVG />
        </div>
      );
    } else if (score > 0) {
      reviews.push(
        <div key={i} className="review">
          <StarFullSVG />
        </div>
      );
    }
    score--;
    i++;
  }

  return <>{reviews}</>;
}

export default StarReview;
