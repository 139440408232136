import React from 'react';
import './expertCard.scss';
import { SearchResult } from '../../Types/searchTypes';
import { getSVGFromAvatarSeed } from '../../helpers';

type Props = {
  searchResultObject: SearchResult;
  photoBytes: string;
};

function ExpertCard({ searchResultObject, photoBytes }: Props) {
  const avatarSVG = getSVGFromAvatarSeed(searchResultObject?.photo || '');

  function truncateDescription(description: string): string {
    if (description.length > 60) {
      return description.substring(0, 60) + '... ';
    } else {
      return description;
    }
  }
  return (
    <>
      <div className="expert-card-container">
        <div className="expert-card-header">
          <div className="expert-card-header-username">
            {searchResultObject.userName}
          </div>
          <div className="expert-card-photo-section">
            <div className="expert-card-photo">
              {searchResultObject.photo &&
              searchResultObject.photo.includes('photoId') ? (
                <>
                  <img src={'data:image/png;base64,' + photoBytes} alt="" />
                </>
              ) : (
                <img src={avatarSVG} />
              )}
            </div>
          </div>
        </div>
        <div className="expert-card-content">
          <div className="expert-card-content-header">
            {searchResultObject.name}
          </div>
          <div className="expert-card-content-description">
            {truncateDescription(searchResultObject.description)}
            {searchResultObject.description.length > 60 ? (
              <strong>see more</strong>
            ) : (
              <></>
            )}
          </div>
          <div className="expert-card-content-price">
            Starting at:<strong> ${searchResultObject.flatRate}</strong>
          </div>
        </div>
        <div className="expert-card-footer">
          <button
            onClick={() => console.log('schedule clicked')}
            type="button"
            className="btn btn-outline-primary"
          >
            Learn More
          </button>
        </div>
      </div>
    </>
  );
}

export default ExpertCard;
