import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './loginAndOutButton.scss';

import { LoginAndOutButtonProps } from './loginAndOutButtonTypes';

const LoginAndOutButton = (props: LoginAndOutButtonProps) => {
  const { loginWithRedirect, logout } = useAuth0();

  function renderButton(): JSX.Element {
    if (props.isLoginButton) {
      return (
        <>
          <div
            onClick={() => {
              loginWithRedirect();
            }}
            className="sign-in-out-button"
          >
            Sign In
          </div>
          <button
            onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
            type="button"
            className="btn btn-outline-primary"
          >
            Sign Up
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            onClick={() => logout({ returnTo: window.location.origin })}
            className="btn btn-outline-dark btn-sm"
          >
            Sign Out
          </button>
        </>
      );
    }
  }

  return renderButton();
};

export default LoginAndOutButton;
