import React, { useMemo, ReactElement } from 'react';
import './profileCalendar.scss';
import moment from 'moment';
import { momentLocalizer, Calendar } from 'react-big-calendar';
import CustomDayEvent from './customDayEvent/customDayEvent';
import CustomWeekEvent from './customWeekEvent/customWeekEvent';
import CustomMonthEvent from './customMonthEvent/customMonthEvent';
import CustomAgendaEvent from './customAgendaEvent/customAgendaEvent';
import CustomAgendaDate from './customAgendaDate/customAgendaDate';
import CustomToolbar from './customToolbar/customToolbar';
import { CalendarEvent } from './profileCalendarTypes';
import { ExpertEvent } from '../../Types/eventTypes';
import { ExpertAvailability } from '../../Types/profilePageTypes';

type Props = {
  expertEvents?: ExpertEvent[];
  availabilityData?: ExpertAvailability;
};

function ProfileCalendar(props: Props): ReactElement {
  console.log('expertEvents in Profile calendar: ', props.expertEvents);

  // Map API events to big calendar event types.
  const events: CalendarEvent[] = [];

  const eventTypeMap = {
    PENDING: 'request',
    ACCEPTED: 'appointment',
    COMPLETED: 'past',
    DECLINED: 'past',
    MISSED: 'past',
    REQUEST_NEW_TIME: 'past',
  };

  if (props.expertEvents) {
    for (const apiEvent of props.expertEvents) {
      if (
        apiEvent.state != 'REQUEST_NEW_TIME' &&
        apiEvent.state != 'DECLINED' &&
        apiEvent.state != 'CANCELLED'
      ) {
        const eventType: string = apiEvent.state;
        events.push({
          title: apiEvent.nicheName,
          description: apiEvent.nicheDescription,
          learner: apiEvent.learnerUserId,
          start: new Date(apiEvent.startTime),
          end: new Date(apiEvent.endTime),
          type: eventTypeMap[eventType as keyof typeof eventTypeMap],
          id: apiEvent.id.toString(),
        });
      }
    }
    console.log('events: ', events);
  }

  const localizer = momentLocalizer(moment);
  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    []
  );

  // Setting variable to the events data passed in.
  let availability: any = [];
  if (props.availabilityData) {
    const apiTimes = props.availabilityData;
    availability = convertAPITimes(apiTimes);
  }

  function parseAPITimeString(timeString: string, dayOfWeek: number) {
    const times = [];
    if (timeString === '') {
      return [];
    }
    const timeRanges = timeString.split('|');
    for (const timeRange of timeRanges) {
      const start = Number(timeRange.split(',')[0].split(':')[0]);
      const end = Number(timeRange.split(',')[1].split(':')[0]);

      // Inner loop to account for longer time ranges.
      for (let i = start; i < end; i++) {
        times.push({
          startHour: i,
          endHour: i + 1,
          day: dayOfWeek,
        });
      }
    }
    return times;
  }

  function convertAPITimes(apiTimes: ExpertAvailability) {
    let times = parseAPITimeString(apiTimes.sundayTimes, 0);
    times = times.concat(parseAPITimeString(apiTimes.mondayTimes, 1));
    times = times.concat(parseAPITimeString(apiTimes.tuesdayTimes, 2));
    times = times.concat(parseAPITimeString(apiTimes.wednesdayTimes, 3));
    times = times.concat(parseAPITimeString(apiTimes.thursdayTimes, 4));
    times = times.concat(parseAPITimeString(apiTimes.fridayTimes, 5));
    times = times.concat(parseAPITimeString(apiTimes.saturdayTimes, 6));

    return times;
  }
  /*eslint-disable */
  const dateCellAvailabilityWrapper = (dateCellWrapperProps: any) => {
    /*eslint-enable */
    let isAvailable = false;
    for (const time of availability) {
      if (time.day == dateCellWrapperProps.value.getDay()) {
        isAvailable = true;
      }
    }
    const style = {
      display: 'flex',
      flex: 1,
      backgroundColor: isAvailable ? '#F5FAFF' : 'white',
    };
    return <div style={style}>{dateCellWrapperProps.children}</div>;
  };

  /*eslint-disable */
  const timeSlotWrapper = (timeSlotWrapperProps: any) => {
    /*eslint-enable */
    let isAvailable = false;
    for (const time of availability) {
      if (
        time.startHour == timeSlotWrapperProps.value.getHours() &&
        time.day == timeSlotWrapperProps.value.getDay()
      ) {
        isAvailable = true;
      }
    }
    const style = {
      display: 'flex',
      flex: 1,
      backgroundColor: isAvailable ? '#F5FAFF' : 'white',
    };
    return <div style={style}>{timeSlotWrapperProps.children}</div>;
  };

  const components = {
    day: {
      event: CustomDayEvent,
    },
    week: {
      event: CustomWeekEvent,
    },
    month: {
      event: CustomMonthEvent,
    },
    agenda: {
      time: CustomAgendaEvent,
      date: CustomAgendaDate,
    },
    dateCellWrapper: dateCellAvailabilityWrapper,
    timeSlotWrapper: timeSlotWrapper,
    toolbar: CustomToolbar,
  };

  const MyCalendar = () => (
    <div className="myCustomHeight">
      <Calendar
        defaultView="week"
        defaultDate={defaultDate}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={events}
        components={components}
      />
    </div>
  );

  return (
    <>
      <div className="calendar-section">{MyCalendar()}</div>
    </>
  );
}

export default ProfileCalendar;
