import React from 'react';
// import Header from '../../Components/header/header';
// import SearchBar from '../../Components/searchBar/searchBar';
// import { Link } from 'react-router-dom';
// import { AllRoutes } from '../../App';
// import SearchResultsGrid from '../../Components/searchResultsGrid/searchResultsGrid';
// import Footer from '../../Components/footer/footer';

import './homepage.scss';
// import { ReactComponent as CircleArrowRightSVG } from '../../Assets/SVGs/circle-arrow-right.svg';

// enum Filter {
//   RELEVANCE = 'RELEVANCE',
//   PRICE_LOW_HIGH = 'PRICE_LOW_HIGH',
//   PRICE_HIGH_LOW = 'PRICE_HIGH_LOW',
// }

function Homepage() {
  return (
    <>
      <div className="page-background">
        <div className="header-section-homepage-2">
          <div className="heading-text">
            <div className="welcome-text">
              Welcome to WeTrieve, a platform to vent, share, and validate your
              feelings.
            </div>
          </div>
          <div className="youtube-video">
            <iframe
              width="560"
              height="349"
              src="https://www.youtube.com/embed/-jrR7_WmSmU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="google-form">
            <div className="google-form-text">
              Wetrieve is currently pre-launch. If you are interested in signing
              up or leaving feedback, we would greatly appreacitate you filling
              out this{' '}
              <a
                href="https://forms.gle/y1Jwr2dy3RGF9eyMA"
                target="_blank"
                rel="noopener noreferrer"
              >
                google form
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Header></Header>
        <div className="header-section-homepage">
          <div className="heading-text">
            <div className="welcome-text">
              Welcome to WeTrieve, a community of humans helping humans.
            </div>
            <div className="find-experts-text">Find Your Expert!</div>
          </div>
          <div className="container search-bar-trending">
            <SearchBar></SearchBar>
          </div>
          <hr></hr>
          <div className="anyone-expert">
            <div className="anyone-expert-text">Anyone can be an Expert!</div>
            <div className="anyone-expert-money">
              Start earning money for your unique expertise!
            </div>
            <div className="anyone-expert-button">
              <Link
                type="button"
                className="btn btn-primary btn-sm"
                to={AllRoutes.ABOUT}
              >
                <div className="button-contents">
                  <span>
                    <CircleArrowRightSVG />
                  </span>
                  BECOME AN EXPERT
                </div>
              </Link>
            </div>
          </div>
          <hr></hr>
        </div>
        <div className="informational-cards">
          <Link to={AllRoutes.SEARCH} className="informational-card">
            <div className="informational-card-header-text">
              Browse the Community
            </div>
            <div className="informational-card-support-text">
              See All Experts on WeTrieve
            </div>
            <div className="informational-card-image" id="browse-com"></div>
            <div className="learn-more-button">
              <button type="button" className="btn btn-outline-primary btn-sm">
                LEARN MORE
              </button>
            </div>
          </Link>
          <div className="informational-card">
            <div className="informational-card-header-text">
              How WeTrieve Works
            </div>
            <div className="informational-card-support-text">
              Learn more about our product
            </div>
            <div className="informational-card-image" id="how-wt-works"></div>
            <div className="learn-more-button">
              <button type="button" className="btn btn-outline-primary btn-sm">
                LEARN MORE
              </button>
            </div>
          </div>
          <Link to={AllRoutes.SEARCH} className="informational-card">
            <div className="informational-card-header-text">
              Discover More About WeTrieve
            </div>
            <div className="informational-card-support-text">
              Read our story and mission
            </div>
            <div className="informational-card-image" id="discover-more"></div>
            <div className="learn-more-button">
              <button type="button" className="btn btn-outline-primary btn-sm">
                LEARN MORE
              </button>
            </div>
          </Link>
        </div>
        <div className="comming-soon">Checkout some of our experts!</div>
        <div className="container">
          <SearchResultsGrid searchParams={''} filterType={Filter.RELEVANCE} />
        </div>
      </div>
      <Footer /> */}
    </>
  );
}

export default Homepage;
