import React from 'react';
// import { ReactComponent as LogoSVG } from '../../Assets/SVGs/logo-grey.svg';
import { Link } from 'react-router-dom';
import './onboardingNavbar.scss';

function OnboardingNavbar() {
  return (
    <>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 logo">
              {/* <div className="logo-text">
                <LogoSVG />
                WeTrieve
              </div> */}
              <div className="header-logo-navbar"></div>
            </div>
            <div className="col-3 expert-text">
              <div>Becoming an Expert</div>
            </div>
            <div className="col-3">
              <div className="cancel-button">
                <Link className="btn btn-outline-dark btn-sm" to="/">
                  SAVE / EXIT
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default OnboardingNavbar;
