/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactElement, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import OnboardingNavbar from '../../Components/onboardingNavbar/onboardingNavbar';
import OnboardingProgressTracker from '../../Components/onboardingProgressTracker/onboardingProgressTracker';
import { useExpertProfile } from '../../Hooks/useExpertProfile';
import { Outlet, useNavigate } from 'react-router-dom';
import { AllRoutes } from '../../App';
import {
  sendExpertAPIData,
  postExpertProfilePhoto,
} from '../../Services/expertServices';
import { putBackStep } from '../../Services/expertServices';

export type ExpertOnboardingSetters = {
  setIsStepComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setStepInput: React.Dispatch<React.SetStateAction<string>>;
  setFileInput: React.Dispatch<React.SetStateAction<File | undefined>>;
  postStatus: number;
  postPhotoStatus: number;
};

type Props = {
  authUser: string;
};

const ExpertOnboardingWidget = ({ authUser }: Props): ReactElement => {
  const { isLoading, data, refetch } = useExpertProfile(authUser);
  const [isStepComplete, setIsStepComplete] = useState<boolean>(false);
  const [stepInput, setStepInput] = useState<string>('');
  const [fileInput, setFileInput] = useState<File>();
  const [postStatus, setPostStatus] = useState<number>();
  const [postPhotoStatus, setPostPhotoStatus] = useState<number>(); // todo: make sure photo step uses this
  const navigate = useNavigate();

  // if no data, need to call expert/onboard post to tell BE to create a new expert

  function navigateExpertOnboarding(): void {
    // @ts-ignore
    if (data?.onboard_step) {
      if (data?.onboard_step == 'COMPLETE') {
        navigate(AllRoutes['COMPLETE'] + '/' + data.userName);
      } else {
        navigate(AllRoutes[data?.onboard_step || 'USERNAME']);
      }
    } else {
      navigate(AllRoutes['USERNAME']);
    }

    console.log(
      `Expert Onboarding: navigating to ${
        data?.onboard_step || 'USERNAME'
      } page `
    );
  }

  async function handleNext(): Promise<void> {
    const postResponse = await sendExpertAPIData({
      stepInput,
      onboardStep: data?.onboard_step || 'USERNAME',
      userId: authUser,
    });

    // If onboard step is PHOTO and there is a fileInput, post the file
    if (data?.onboard_step == 'PHOTO') {
      if (fileInput != null) {
        const postPhotoResponse = await postExpertProfilePhoto({
          fileName: stepInput,
          fileInput: fileInput,
        });
        setPostPhotoStatus(postPhotoResponse.status);
      }
    }
    setPostStatus(postResponse.status);
    refetch();
  }

  async function handleBack(): Promise<void> {
    await putBackStep(authUser);

    refetch();
  }

  useEffect(() => {
    navigateExpertOnboarding();
  }, [data]);

  return (
    <>
      <OnboardingNavbar></OnboardingNavbar>
      {isLoading ? (
        <p>FETCHING EXPERT PROFILE...</p>
      ) : (
        <>
          <OnboardingProgressTracker
            currentStep={data?.onboard_step || 'USERNAME'}
          />
          <hr></hr>
          <Outlet
            context={{
              setIsStepComplete,
              setStepInput,
              setFileInput,
              postStatus,
              postPhotoStatus,
            }}
          />
          <hr></hr>
          <div className="navigation-buttons">
            <button
              className="btn btn-outline-dark btn-sm"
              type="button"
              onClick={handleBack}
            >
              BACK
            </button>
            <button
              className={
                'btn btn-outline-primary btn-sm ' +
                (isStepComplete ? '' : 'disabled')
              }
              type="button"
              onClick={handleNext}
            >
              NEXT
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ExpertOnboardingWidget;
