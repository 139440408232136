import React, { ReactElement } from 'react';
import './customMonthEvent.scss';
import { CalendarEvent } from '../profileCalendarTypes';

type Props = {
  event: CalendarEvent;
};

function CustomMonthEvent(props: Props): ReactElement {
  console.log('month props: ', props);
  return (
    <div className="month-event-container">
      <div className={'month-event ' + props.event.type}>
        {props.event.title}
      </div>
    </div>
  );
}

export default CustomMonthEvent;
