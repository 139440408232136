import React from 'react';
import './profileCalendarTab.scss';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as QuestionMarkCircleSVG } from '../../Assets/SVGs/question-mark-circle.svg';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ProfileCalendar from '../../Components/profileCalendar/profileCalendar';
import SetAvailabilityModal from '../setAvailabilityModal/setAvailabilityModal';
import {
  useExpertEvents,
  useExpertAvailability,
} from '../../Hooks/expertProfilePageHooks';

type Props = {
  userName: string;
};

function profileCalendarTab(props: Props): JSX.Element {
  const call_information_list = [
    { text: 'Upcomming Calls', number: 0 },
    { text: 'Pending Requests', number: 0 },
    { text: 'Completed Calls', number: 0 },
  ];

  const availability_set = false;

  const { isLoading: isExpertEventsLoading, data: expertEvents } =
    useExpertEvents(props.userName);

  const { isLoading: isAvailabilityLoading, data: availabilityData } =
    useExpertAvailability(props.userName);

  console.log('isExpertEventsLoading: ', isExpertEventsLoading);
  console.log('expertEvents: ', expertEvents);
  console.log('availabilityData: ', availabilityData);

  let upcommingCalls = 0;
  let pendingCalls = 0;
  let completedCalls = 0;
  if (expertEvents) {
    for (const event of expertEvents) {
      if (event.state === 'PENDING') {
        pendingCalls += 1;
      }
      if (event.state === 'COMPLETED') {
        completedCalls += 1;
      }
      if (event.state === 'ACCEPTED') {
        upcommingCalls += 1;
      }
    }
    call_information_list[0].number = upcommingCalls;
    call_information_list[1].number = pendingCalls;
    call_information_list[2].number = completedCalls;
  }

  return (
    <>
      {isAvailabilityLoading || isExpertEventsLoading ? (
        <div className="spinner-border text-primary" role="status"></div>
      ) : (
        <>
          <div className="calendar-tab-container">
            <div className="calendar-tab-header">
              Schedule
              <ReactTooltip />
              <span data-tip="View Schedule and Avalability">
                <QuestionMarkCircleSVG />
              </span>
            </div>
            <div className="call-information-sections">
              {call_information_list.map((call_info, index) => {
                return (
                  <div key={index} className="call-information-section">
                    <div className="call-information-number">
                      {call_info.number}
                    </div>
                    <div className="call-information-text">
                      {call_info.text}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="availability-section">
              <div className="availability-section-header">Availability</div>
              {!availability_set ? (
                <div className="set-availability-section">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#setAvailabilityModal"
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    SET AVAILABILITY
                  </button>
                  <div
                    className="modal fade"
                    id="setAvailabilityModal"
                    tabIndex={-1}
                    aria-labelledby="availabilityModal"
                    aria-hidden="true"
                  >
                    <SetAvailabilityModal userName={props.userName} />
                  </div>
                </div>
              ) : (
                <>Edit</>
              )}
            </div>
            {!isAvailabilityLoading ? (
              <ProfileCalendar
                expertEvents={expertEvents}
                availabilityData={availabilityData}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default profileCalendarTab;
