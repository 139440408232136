import React, { ReactElement } from 'react';
import { ReactComponent as QuestionMarkCircleSVG } from '../../Assets/SVGs/question-mark-circle.svg';
import { ReactComponent as ChevronSVG } from '../../Assets/SVGs/chevron-right.svg';
import './faqDropdown.scss';

function FaqDropdown(): ReactElement {
  return (
    <>
      <div className="faq-container">
        <div
          className="collapse-faq"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <QuestionMarkCircleSVG /> FAQ
          <div className="collapse-header-chevron">
            <ChevronSVG />
          </div>
        </div>
        <div className="collapse" id="collapseExample">
          <div className="card card-body">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How does payment work?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Prices shown below are what the expert expects for a call.
                    Prices can be negotiated once the expert contacts you. All
                    payments are handled by you and the expert via Venmo, Zelle,
                    Apple Cash or other money transfering methods.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    How will the expert reach me?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    After the request has been sent, the expert will reach out
                    to you via SMS to schedule a call and payment if necessary.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThre"
                    aria-expanded="true"
                    aria-controls="collapseThre"
                  >
                    How does scheduling work?
                  </button>
                </h2>
                <div
                  id="collapseThre"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You and the expert are responsible for finding a time that
                    works for both of you to conduct the call.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqDropdown;
