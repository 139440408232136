import React, { useState, useEffect } from 'react';
import './searchResultsGrid.scss';
import ExpertCard from '../../Components/expertCard/expertCard';
import { useSearchResults } from '../../Hooks/useSearchResults';
import { useProfilePhotos } from '../../Hooks/expertProfilePageHooks';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { SearchResult } from '../../Types/searchTypes';
import { ScheduleExpertModal } from '../../Components/Modals';

type Props = {
  searchParams: string;
  filterType: Filter;
};

enum Filter {
  RELEVANCE = 'RELEVANCE',
  PRICE_LOW_HIGH = 'PRICE_LOW_HIGH',
  PRICE_HIGH_LOW = 'PRICE_HIGH_LOW',
}

function SearchResultsGrid({ searchParams, filterType }: Props) {
  const [photoList, setPhotoList] = useState<string>('');
  const [showExpertModal, setShowExpertModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<SearchResult>();
  const [modalPhoto, setModalPhoto] = useState<string>('');
  const [filterTypeVar, setFilterTypeVar] = useState<Filter>(Filter.RELEVANCE);

  const {
    isLoading: isSearchResultsLoading,
    data: searchResults,
    refetch: refetchSearchResults,
  } = useSearchResults(searchParams || '', filterTypeVar);

  const {
    isLoading: loadingPhotos,
    data: dataPhotos,
    refetch: refetchPhotos,
  } = useProfilePhotos(photoList);

  function grabRelevantPhoto(searchResultObject: SearchResult): string {
    if (dataPhotos != null) {
      for (let i = 0; i < dataPhotos.length; i++) {
        const photoObj = dataPhotos[i];
        if (photoObj.fileName == searchResultObject.photo) {
          return photoObj.imageBytes;
        }
      }
    }
    return '';
  }

  function handleModalClose() {
    setShowExpertModal(false);
  }

  function handleCardClick(searchResultObject: SearchResult) {
    setShowExpertModal(true);
    setModalContent(searchResultObject);
    setModalPhoto(grabRelevantPhoto(searchResultObject));
  }

  useEffect(() => {
    if (searchResults) {
      const lst: string[] = [];
      searchResults?.forEach(function (item) {
        if (!item.photo.includes('seed')) {
          lst.push(item.photo);
        }
      });
      setPhotoList(lst.join(', '));
    }
  }, [searchResults]);

  useEffect(() => {
    refetchPhotos();
  }, [photoList]);

  useEffect(() => {
    setFilterTypeVar(filterType);
    refetchSearchResults();
  }, [filterType]);

  return (
    <>
      <ScheduleExpertModal
        showExpertModal={showExpertModal}
        handleModalClose={handleModalClose}
        modalContent={modalContent}
        modalPhoto={modalPhoto}
      />
      {isSearchResultsLoading || loadingPhotos ? (
        <LoadingSpinner />
      ) : (
        <div className="expert-cards-grid">
          <div className="row gy-4 gx-1">
            {searchResults && dataPhotos?.length != 0 && dataPhotos ? (
              <>
                {(searchResults || []).map((searchResultObject, index) => {
                  return (
                    <div className="col" key={index}>
                      <div
                        className="expert-card"
                        onClick={() => handleCardClick(searchResultObject)}
                      >
                        <ExpertCard
                          searchResultObject={searchResultObject}
                          photoBytes={grabRelevantPhoto(searchResultObject)}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div>No Results...</div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SearchResultsGrid;
