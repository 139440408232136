import axios from 'axios';
import { NicheObj } from '../Types/fetchTypes';
import { APIReturn } from '../Types/APITypes';

const instance = axios.create({
  baseURL: 'http://localhost:8000/',
  timeout: 10000,
});

//Post a new niche that a user created
export async function sendExpertNicheAPIData(
  data: NicheObj
): Promise<APIReturn<NicheObj>> {
  console.log('Create Niche Data: ', data);
  try {
    const response = await instance.post(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/niches/onboard',
      {
        description: data.description,
        dynamicRate: data.dynamicRate,
        flatRate: data.flatRate,
        name: data.name,
        tags: data.tags,
        userName: data.userName,
      }
    );
    console.log(response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: sendExpertNicheAPIData');
    return {
      status: 204,
      error: 'Error: sendExpertNicheAPIData',
    };
  }
}

//Send edited data from a user
export async function sendEditedNicheAPIData(
  data: NicheObj
): Promise<APIReturn<NicheObj>> {
  console.log('Editing Niche Data: ', data);
  try {
    const response = await instance.post(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/niches/edit',
      {
        description: data.description,
        dynamicRate: data.dynamicRate,
        flatRate: data.flatRate,
        name: data.name,
        tags: data.tags,
        userName: data.userName,
      }
    );
    console.log(response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: sendExpertNicheAPIData');
    return {
      status: 204,
      error: 'Error: sendExpertNicheAPIData',
    };
  }
}

//Send edited data from a user
export async function deleteNicheData(
  userName: string,
  nicheName: string
): Promise<APIReturn<NicheObj>> {
  console.log('Deleting Niche Data: ', userName, nicheName);
  try {
    const response = await instance.delete(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/niches/delete',
      { params: { userName: userName, nicheName: nicheName } }
    );
    console.log(response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: deleteNicheData', error);
    return {
      status: 204,
      error: 'Error: deleteNicheData',
    };
  }
}

// Get Niches for an expert
export async function getExpertNicheData(
  userName: string
): Promise<APIReturn<NicheObj[]>> {
  try {
    const response = await instance.get(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/niches/profile',
      {
        headers: {
          userName: userName,
        },
      }
    );
    console.log('niche response:', response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    return {
      status: 204,
      error: 'Error: sendExpertNicheAPIData',
    };
  }
}
