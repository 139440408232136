import React, { ReactElement, useState } from 'react';
import './appointmentPopout.scss';
import { CalendarEvent } from '../profileCalendar/profileCalendarTypes';
import { ReactComponent as AvatarSVG } from '../../Assets/SVGs/avatar.svg';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as QuestionMarkCircleSVG } from '../../Assets/SVGs/system-dialog-help.svg';
import { updateEventState } from '../../Services/eventsServices';

type Props = {
  event: CalendarEvent;
};

function AppointmentPopout({ event }: Props): ReactElement {
  // State variable that monitors if event was toggled succesfully
  const [properEventToggle, setProperEventToggle] = useState<boolean>(true);

  function extractHour(data: Date): string {
    let hours = data.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    if (hours > 12) {
      hours = hours - 12;
    }
    return ' - ' + hours + ' ' + ampm;
  }

  function getStatusText(eventType: string): string {
    if (eventType === 'past') {
      return 'Complete';
    } else if (eventType === 'appointment') {
      return 'Accepted';
    } else {
      return 'Pending';
    }
  }

  async function handleDeclineRequest(eventId: string): Promise<void> {
    console.log('declining event with id: ', eventId);
    const response = await updateEventState(Number(eventId), 'DECLINED');
    if (response?.status == 200) {
      console.log('event declined successfully');
      setProperEventToggle(true);
      document
        .getElementById('appointmentPopoutModal' + '-' + eventId)
        ?.click();
      location.reload();
    } else {
      setProperEventToggle(true);
    }
  }

  async function handleAcceptRequest(eventId: string): Promise<void> {
    console.log('accepting event with id: ', eventId);
    const response = await updateEventState(Number(eventId), 'ACCEPTED');
    if (response?.status == 200) {
      console.log('event accepted successfully');
      setProperEventToggle(true);
      document
        .getElementById('appointmentPopoutModal' + '-' + eventId)
        ?.click();
      location.reload();
    } else {
      setProperEventToggle(true);
    }
  }

  async function handleNewTimeRequest(eventId: string): Promise<void> {
    console.log('requesting new time event with id: ', eventId);
    const response = await updateEventState(
      Number(eventId),
      'REQUEST_NEW_TIME'
    );
    if (response?.status == 200) {
      console.log('event requesting new time successfully');
      setProperEventToggle(true);
      document
        .getElementById('appointmentPopoutModal' + '-' + eventId)
        ?.click();
      location.reload();
    } else {
      setProperEventToggle(true);
    }
  }

  async function handleCancelRequest(eventId: string): Promise<void> {
    console.log('cancelling new time event with id: ', eventId);
    const response = await updateEventState(Number(eventId), 'CANCELLED');
    if (response?.status == 200) {
      console.log('event cancelled successfully');
      setProperEventToggle(true);
      document
        .getElementById('appointmentPopoutModal' + '-' + eventId)
        ?.click();
      location.reload();
    } else {
      setProperEventToggle(true);
    }
  }

  function renderFooter(eventType: string): JSX.Element {
    switch (eventType) {
      case 'past':
        return <></>;
      case 'appointment':
        return (
          <>
            <div className="footer-actions">
              <div className="footer-tooltip">
                <ReactTooltip />
                <span data-tip="You've accepted this appointment. Expect a call from the learner at the scheduled time.">
                  <QuestionMarkCircleSVG />
                </span>
              </div>
              <div className="footer-options">
                <div className="footer-options-descriptions">
                  You have accepted this request! You may...
                </div>
                <div className="footer-options-links">
                  <div
                    className="footer-options-link"
                    onClick={() => handleCancelRequest(event.id)}
                  >
                    Cancel Request
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'request':
        return (
          <>
            <div className="footer-actions">
              <div className="footer-tooltip">
                <ReactTooltip />
                <span data-tip="A learner wants to meet with you! Choose from the actions below.">
                  <QuestionMarkCircleSVG />
                </span>
              </div>
              <div className="footer-options">
                <div className="footer-options-descriptions">
                  Something wrong? You may...
                </div>
                <div className="footer-options-links">
                  <div
                    className="footer-options-link"
                    onClick={() => handleDeclineRequest(event.id)}
                  >
                    Decline Request
                  </div>
                  <div className="footer-options-link-text">or</div>
                  <div
                    className="footer-options-link"
                    onClick={() => handleNewTimeRequest(event.id)}
                  >
                    Request New time
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="accept-button">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                // data-bs-dismiss="modal"
                onClick={() => handleAcceptRequest(event.id)}
              >
                ACCEPT
              </button>
            </div>
            {properEventToggle ? (
              <></>
            ) : (
              <div className="error-message">Oops, somethine went wrong</div>
            )}
          </>
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <div className={'niche-header-dot ' + event.type}></div>
            <h1
              className="modal-title fs-5"
              id={'#appointmentPopoutModal' + '-' + event.id}
            >
              Niche Appointment
            </h1>
            <div className="appointment-time">{extractHour(event.start)}</div>
            <div className={'appointment-status ' + event.type}>
              {getStatusText(event.type)}
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id={'#appointmentPopoutModal' + '-' + event.id}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="popout-info">
              <div className="popout-row">
                <div className="popout-row-title">Learner</div>
                <div className="popout-row-info">
                  <div className="popout-row-text">
                    <div className="popout-row-svg">
                      <AvatarSVG />
                    </div>
                    {event.learner}
                  </div>
                  <div className="popout-row-description">
                    The Learner will call you at the designated appointment
                    time.
                  </div>
                </div>
              </div>
              <div className="popout-row">
                <div className="popout-row-title">Niche</div>
                <div className="popout-row-info">
                  <div className="popout-row-text">{event.title}</div>
                  <div className="popout-row-description">
                    {event.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer popout">{renderFooter(event.type)}</div>
        </div>
      </div>
    </>
  );
}

export default AppointmentPopout;
