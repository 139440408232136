import React from 'react';
import './onboardingProgressTracker.scss';
import { ReactComponent as SectionCompleteSVG } from '../../Assets/SVGs/section-complete.svg';
import { ReactComponent as OnboardArrowSVG } from '../../Assets/SVGs/onboard-arrow.svg';
import { OnboardingStep } from '../../Hooks/types';

type TrackerStep = {
  label: string;
  order: number;
  section_complete: boolean;
  section_inprogress: boolean;
};

const stepMap: { [key in OnboardingStep]: number } = {
  USERNAME: 1,
  PHONENUMBER: 2,
  BIO: 3,
  CREDIBILITY: 4,
  PHOTO: 5,
  COMPLETE: 0,
};

type Props = {
  currentStep: OnboardingStep;
};

function OnboardingProgressTracker({ currentStep }: Props) {
  // Fill out the progress bar depending on which sections are complete
  const bar_percentage = 25 * (stepMap[currentStep] - 1);

  const trackerSteps: TrackerStep[] = [
    {
      label: 'Username',
      order: stepMap.USERNAME,
      section_complete: stepMap[currentStep] > stepMap.USERNAME,
      section_inprogress: stepMap[currentStep] === stepMap.USERNAME,
    },
    {
      label: 'Phone',
      order: stepMap.PHONENUMBER,
      section_complete: stepMap[currentStep] > stepMap.PHONENUMBER,
      section_inprogress: stepMap[currentStep] === stepMap.PHONENUMBER,
    },
    {
      label: 'Bio',
      order: stepMap.BIO,
      section_complete: stepMap[currentStep] > stepMap.BIO,
      section_inprogress: stepMap[currentStep] === stepMap.BIO,
    },
    {
      label: 'Credibility',
      order: stepMap.CREDIBILITY,
      section_complete: stepMap[currentStep] > stepMap.CREDIBILITY,
      section_inprogress: stepMap[currentStep] === stepMap.CREDIBILITY,
    },
    {
      label: 'Photo',
      order: stepMap.PHOTO,
      section_complete: stepMap[currentStep] > stepMap.PHOTO,
      section_inprogress: stepMap[currentStep] === stepMap.PHOTO,
    },
  ];

  return (
    <>
      <div className="container">
        <div className="progress-container">
          <div
            className="progress-b"
            style={{ width: bar_percentage + '%' }}
            id="progress-b"
          ></div>
          {trackerSteps.map((step) => {
            return (
              <div key={step.order}>
                <div className="circle-section">
                  {step.section_inprogress && (
                    <div className="onboard-arrow">
                      <OnboardArrowSVG />
                    </div>
                  )}
                  <div
                    className={
                      'circle ' +
                      (step.section_complete ? 'complete' : '') +
                      (step.section_inprogress ? 'active' : '')
                    }
                  >
                    {step.section_complete ? (
                      <SectionCompleteSVG />
                    ) : (
                      <div
                        className={
                          'section-number ' +
                          (!step.section_inprogress ? 'inactive' : '')
                        }
                      >
                        {step.order}
                      </div>
                    )}
                  </div>
                  <div className="section-text">
                    <span>{step.label}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default OnboardingProgressTracker;
