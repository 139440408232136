import React, { ReactElement } from 'react';
import './customDayEvent.scss';
import { CalendarEvent } from '../profileCalendarTypes';
import AppointmentPopout from '../../appointmentPopout/appointmentPopout';

type Props = {
  event: CalendarEvent;
};

function CustomDayEvent(props: Props): ReactElement {
  function onRequestAction(eventID: string, actionType: string): void {
    console.log(actionType, eventID);
    return;
  }

  function onAppointmentDecline(eventID: string): void {
    console.log(eventID);
    return;
  }

  // Function that determines which tab to render
  function renderEventOptions(): ReactElement | undefined {
    switch (props.event.type) {
      case 'request':
        return (
          <>
            <div
              className="event-action"
              onClick={() => onRequestAction(props.event.id, 'decline')}
            >
              Decline
            </div>
            <div className="event-action-text-filler">or</div>
            <div
              className="event-action"
              onClick={() => onRequestAction(props.event.id, 'request')}
            >
              Request New time
            </div>
            <div className="event-button">
              <button
                type="button"
                onClick={() => onRequestAction(props.event.id, 'accept')}
                className="btn btn-primary btn-sm"
              >
                ACCEPT
              </button>
            </div>
          </>
        );
      case 'appointment':
        return (
          <>
            <div
              className="event-action"
              onClick={() => onAppointmentDecline(props.event.id)}
            >
              Cancel Request
            </div>
            <div className="event-status accepted">ACCEPTED</div>
          </>
        );
      case 'past':
        return (
          <>
            <div className="event-status complete">COMPLETE</div>
          </>
        );
    }
  }

  const withPmAmTime = props.event.start.toLocaleTimeString('en-US', {
    // en-US can be set to 'default' to use user's browser settings
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <div
      className={'day-event-container ' + props.event.type}
      data-bs-toggle="modal"
      data-bs-target={'#appointmentPopoutModal' + '-' + props.event.id}
    >
      <div className="event-info">
        <div className="event-title">{props.event.title}</div>
        <div className="event-time">{withPmAmTime}</div>
      </div>
      <div className="event-options">{renderEventOptions()}</div>
      <div
        className="modal fade"
        id={'appointmentPopoutModal' + '-' + props.event.id}
        tabIndex={-1}
        aria-labelledby={'#appointmentPopoutModal' + '-' + props.event.id}
        aria-hidden="true"
      >
        <AppointmentPopout event={props.event} />
      </div>
    </div>
  );
}

export default CustomDayEvent;
