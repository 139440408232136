/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import './customToolbar.scss';
import moment from 'moment';
import { ReactComponent as RightChevronSVG } from '../../../Assets/SVGs/chevron-right.svg';
import { ReactComponent as LeftChevronSVG } from '../../../Assets/SVGs/chevron-left.svg';

function CustomToolBar(props: any): any {
  const [viewState, setViewState] = useState('month');
  //   const [StudyTimechecked, setStudyTimechecked] = useState(false);
  const getCustomToolbar = () => {
    // const toolbarDate = props.date;
    const goToDayView = () => {
      props.onView('day');
      setViewState('day');
    };
    const goToWeekView = () => {
      props.onView('week');
      setViewState('week');
    };
    const goToMonthView = () => {
      props.onView('month');
      setViewState('month');
    };
    const goToAgendaView = () => {
      props.onView('agenda');
      setViewState('agenda');
    };
    const goToBack = () => {
      const view = viewState;
      const mDate = props.date;
      let newDate;
      if (view === 'month') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      } else if (view === 'week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 1,
          1
        );
      }
      props.onNavigate('prev', newDate);
    };
    const goToNext = () => {
      const view = viewState;
      const mDate = props.date;
      let newDate;
      if (view === 'month') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      } else if (view === 'week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 1,
          1
        );
      }
      props.onNavigate('next', newDate);
    };

    const goToToday = () => {
      const now = new Date();
      props.date.setMonth(now.getMonth());
      props.date.setYear(now.getFullYear());
      props.date.setDate(now.getDate());
      props.onNavigate('current');
    };

    const getMonthYear = () => {
      const date = moment(props.date);
      const month = date.format('MMMM');
      const year = date.format('YYYY');
      return month + ' , ' + year;
    };

    return (
      <div className="custom-toolbar">
        <div className="toolbar-view-toggle">
          <div
            className="btn-group"
            role="group"
            aria-label="Default button group"
          >
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={goToMonthView}
            >
              <span className="label-filter-off">Month</span>
            </button>
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={goToDayView}
            >
              <span className="label-filter-off">Day</span>
            </button>
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={goToWeekView}
            >
              <span className="label-filter-off">Week</span>
            </button>
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={goToAgendaView}
            >
              <span className="label-filter-off">Agenda</span>
            </button>
          </div>
        </div>
        <div className="view-navigation">
          <div className="view-navigation-date">{getMonthYear()}</div>
          <div className="view-navigation-toggle">
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={goToBack}
            >
              <LeftChevronSVG />
            </button>
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={goToToday}
              id="today-button"
            >
              Today
            </button>
            <button
              type="button"
              className="btn btn-outline-dark"
              onClick={goToNext}
            >
              <RightChevronSVG />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
}

export default CustomToolBar;
