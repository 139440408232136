import axios from 'axios';
import { APIReturn } from '../Types/APITypes';

const instance = axios.create({
  baseURL: 'http://localhost:8000/',
  timeout: 10000,
});

const REACH_OUT_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/twilio/expertReachoutText';

export async function learnerReachOutRequest(
  expertNumber: string,
  expertUserName: string,
  learnerNumber: string,
  nicheName: string
): Promise<APIReturn<null>> {
  try {
    const response = await instance.post(REACH_OUT_URL, null, {
      params: {
        expertNumber,
        expertUserName,
        learnerNumber,
        nicheName,
      },
    });
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: learnerReachOutRequest');
    console.log(error);
    return {
      status: 204,
      error: 'Error: learnerReachOutRequest',
    };
  }
}
