import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
// import { web } from 'webpack';
import { ExpertOnboardingSetters } from '../../../Widgets/ExpertOnboarding/ExpertOnboardingWidget';
import './expertOnboardingCredentials.scss';
import Form from 'react-bootstrap/Form';

function ExpertOnboardingCredentials() {
  const { setIsStepComplete, setStepInput } =
    useOutletContext<ExpertOnboardingSetters>();
  // Form checker -------
  // URL regex checker
  function isURL(urlInput: string) {
    const urlRegex =
      '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    const url = new RegExp(urlRegex);
    return urlInput.length < 2083 && url.test(urlInput);
  }

  const [personalWebsites, setPersonalWebsites] = useState<Array<string>>(['']);
  const [validWebsites, setValidWebsites] = useState<boolean>(true);
  const [validWebsitesList, setValidWebsitesList] = useState<boolean[]>([
    true,
    true,
    true,
  ]);

  // const [validated, setValidated] = useState(false);

  function handlePersonalWebsiteInput(index: number, value: string): void {
    setPersonalWebsites(['']);
    const newPersonalWebsites = [...personalWebsites];
    newPersonalWebsites[index] = value;
    setPersonalWebsites(newPersonalWebsites);
  }

  function removeWebsite(index: number): void {
    const newPersonalWebsites = [...personalWebsites];
    newPersonalWebsites.splice(index, 1);
    setPersonalWebsites(newPersonalWebsites);
  }

  function addWebsite(): void {
    if (personalWebsites.length < 3) {
      setPersonalWebsites([...personalWebsites, '']);
    }
  }

  // Check if websites are valid, if not disable next button
  function checkForWebsite(): void {
    const temp_list = validWebsitesList;
    if (personalWebsites.length == 1) {
      temp_list[0] = isURL(personalWebsites[0]);
      if (isURL(personalWebsites[0]) || personalWebsites[0] == '') {
        setValidWebsites(true);
      } else {
        setValidWebsites(false);
      }
    } else {
      for (let i = 0; i < personalWebsites.length; i++) {
        const website = personalWebsites[i];

        if (!isURL(website) || website == '') {
          setValidWebsites(false);
          setValidWebsitesList(temp_list);
          temp_list[i] = false;
          setValidWebsitesList(temp_list);
          return;
        } else {
          temp_list[i] = true;
        }
      }
      setValidWebsitesList(temp_list);
      setValidWebsites(true);
    }
  }

  useEffect(() => {
    checkForWebsite();
    setStepInput(String(personalWebsites));
    setIsStepComplete(validWebsites);
    console.log('validWebsitesList: ', validWebsitesList);
  }, [personalWebsites, validWebsites, validWebsitesList]);

  return (
    <>
      <div className="personal-info-wrapper">
        <div className="header-section">
          <div className="header-text">Your Credentials (Optional)</div>
        </div>
        <div className="section-description">
          Include any urls (LinkedIn, Instagrame, ect...) that you think will
          help improve your credibility in your expertise. These urls are a
          great way for others to feel confident in booking time with you.
        </div>
        <div className="personal-link">
          {personalWebsites.map((element, index) => (
            <div className="form-inline" key={index}>
              <Form noValidate validated={validWebsitesList[index]}>
                <Form.Group controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Website URL..."
                    defaultValue="Mark"
                    value={element || ''}
                    onChange={(e) =>
                      handlePersonalWebsiteInput(index, e.target.value)
                    }
                  />
                </Form.Group>
              </Form>
              {index || personalWebsites.length > 1 ? (
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => removeWebsite(index)}
                >
                  Remove
                </button>
              ) : null}
            </div>
          ))}
          {personalWebsites.length < 3 ? (
            <div className="button-section">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={() => addWebsite()}
              >
                Add Another
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default ExpertOnboardingCredentials;
