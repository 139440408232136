import React, { useState, useEffect } from 'react';
import './profileSettingsTab.scss';
import { useExpertSettings } from '../../Hooks/expertProfilePageHooks';
import {
  updateEmailSettings,
  updateActiveSettings,
} from '../../Services/settingsService';
type Props = {
  userId: string;
};

function profileSettingsTab({ userId }: Props): JSX.Element {
  const { isLoading: isSettingsLoading, data: expertSettings } =
    useExpertSettings(userId);

  const [emailSwitchOn, setEmailSwitchOn] = useState<boolean>(false);
  const [hideProfileSwitchOn, setHideProfileSwitchOn] =
    useState<boolean>(false);

  const [settingsChanged, setSettingsChanged] = useState(false);

  useEffect(() => {
    if (expertSettings) {
      setHideProfileSwitchOn(expertSettings.active);
      setEmailSwitchOn(expertSettings.emailNotifications);
    }
  }, [expertSettings]);

  async function handleSaveSettings(): Promise<void> {
    console.log('handleSaveSettings called');
    const emailResp = await updateEmailSettings(userId, emailSwitchOn);
    if (emailResp.status === 200) {
      console.log('email updated succesfully');
    } else {
      console.log('error in email updated', emailResp.error);
    }

    const activeResp = await updateActiveSettings(userId, hideProfileSwitchOn);
    if (emailResp.status === 200) {
      console.log('active profile updated succesfully');
    } else {
      console.log('eractive profil update error', activeResp.error);
    }

    setSettingsChanged(false);
  }
  return (
    <>
      {isSettingsLoading ? (
        <div className="spinner-border text-primary" role="status"></div>
      ) : (
        <div className="profile-settings-container">
          <div className="profile-settings-block" id="email-notifications">
            <div className="block-text">Receive email notifications</div>
            <div className="block-toggle">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={emailSwitchOn}
                  onChange={() => {
                    setEmailSwitchOn(!emailSwitchOn), setSettingsChanged(true);
                  }}
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </div>
          <div className="profile-settings-block" id="hide-profile">
            <div className="block-text">Hide my profile</div>
            <div className="block-toggle">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={hideProfileSwitchOn}
                  onChange={() => {
                    setHideProfileSwitchOn(!hideProfileSwitchOn),
                      setSettingsChanged(true);
                  }}
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </div>
          <div className="save-settings">
            <button
              type="button"
              className={
                'btn btn-primary ' + (!settingsChanged ? 'disabled' : '')
              }
              onClick={() => handleSaveSettings()}
            >
              SAVE
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default profileSettingsTab;
