import useAPI from './useAPI';
import { useState } from 'react';
import { ProfileNicheCards } from '../Types/nicheCardTypes';
import { ExpertAvailability } from '../Types/profilePageTypes';
import { PhotoObject } from '../Types/photoTypes';
import { GetHook } from './types';
import { ExpertEvent } from '../Types/eventTypes';
import { SettingsObject } from '../Types/settingsTypes';
import { StripeAccountLinkObject } from '../Types/stripeTypes';

const GET_PHOTO_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/storage/download';

const GET_PHOTOS_BULK_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/storage/downloadMultiple';

const GET_NICHE_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/niches/profile';

const GET_EXPERT_EVENTS_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/schedules/event/profile';

const GET_AVAILABILITY_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/schedules/availability/profile';

const GET_SETTINGS_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/settings/profile';

const GET_STRIPE_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/stripe/profile';

const useProfilePhoto: GetHook<BlobPart> = (fileName) => {
  const [data, setData] = useState<BlobPart>();
  const [status, setStatus] = useState<number>(400);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  async function refetch() {
    setIsLoading(true);
    // using fetch instead of axios to load photos
    await fetch(GET_PHOTO_URL, {
      method: 'get',
      headers: {
        accept: 'image/jpeg',
        fileName: fileName,
      },
    }).then((response) => {
      if (!response.ok) {
        setError(response.statusText);
      }
      setStatus(response.status);
      response.blob().then((blob) => {
        setData(blob);
      });
      setIsLoading(false);
    });
  }
  return {
    isLoading,
    data,
    error,
    status,
    refetch,
  };
};

// Profile Niche Info
const useProfilePhotos: GetHook<PhotoObject[]> = (fileNames) => {
  return useAPI<PhotoObject[]>({
    method: 'get',
    url: GET_PHOTOS_BULK_URL,
    headers: { fileNames },
  });
};

// this could be a valuable addition (IGNORE FOR NOW)
// type GetHook<TData> = (arg0: string) => APIReturn<TData>;

// Profile Niche Info
const useExpertNiches: GetHook<ProfileNicheCards> = (userName) => {
  return useAPI<ProfileNicheCards>({
    method: 'get',
    url: GET_NICHE_URL,
    headers: { userName },
  });
};

// Profile Niche Info
const useExpertEvents: GetHook<ExpertEvent[]> = (expertUsername) => {
  return useAPI<ExpertEvent[]>({
    method: 'get',
    url: GET_EXPERT_EVENTS_URL,
    headers: { expertUsername },
  });
};

// Availability Info
const useExpertAvailability: GetHook<ExpertAvailability> = (expertUsername) => {
  return useAPI<ExpertAvailability>({
    method: 'get',
    url: GET_AVAILABILITY_URL,
    headers: { expertUsername },
  });
};

// Settings Info
const useExpertSettings: GetHook<SettingsObject> = (expertUserId) => {
  return useAPI<SettingsObject>({
    method: 'get',
    url: GET_SETTINGS_URL,
    headers: { expertUserId },
  });
};

// Settings Info
const useExpertStripe: GetHook<StripeAccountLinkObject> = (userId) => {
  const expertId = parseInt(userId);
  return useAPI<StripeAccountLinkObject>({
    method: 'get',
    url: GET_STRIPE_URL,
    headers: { expertId },
  });
};

export {
  useExpertNiches,
  useProfilePhoto,
  useExpertAvailability,
  useExpertEvents,
  useExpertSettings,
  useExpertStripe,
  useProfilePhotos,
};
