import axios from 'axios';
import { OnboardingStep } from '../Hooks/types';
import { ExpertProfile } from '../Hooks/useExpertProfile';
import {
  ExpertAvailabilityPost,
  ExpertAvailability,
} from '../Types/profilePageTypes';
import { APIReturn } from '../Types/APITypes';

const instance = axios.create({
  baseURL: 'http://localhost:8000/',
  timeout: 10000,
});

const SEND_TWILIO_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/twilio/send';
const VERIFY_TWILIO_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/twilio/verify';
const POST_EXPERT_PROFILE_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/experts/edit';

type PostExpertOnboardInput = {
  onboardStep?: OnboardingStep;
  stepInput?: string;
  userId?: string;
  fileInput?: File;
};

type PostExpertPhotoData = {
  fileName?: string;
  fileInput?: File;
};

/**
 *
 * @param phoneNumber - just a phone number (this function adds the +1)
 * @returns - APIReturn<string>
 */
export async function sendTwilio(
  phoneNumber: string
): Promise<APIReturn<string>> {
  try {
    const resp = await axios({
      method: 'get',
      url: SEND_TWILIO_URL,
      headers: {
        phoneNumber: `+1${phoneNumber}`,
      },
    });
    return {
      status: resp.status,
      data: resp.data,
    };
  } catch (error) {
    return {
      error: error as string,
      status: 409,
    };
  }
}

/**
 *
 * @param phoneNumber - just a phone number (this function adds the +1)
 * @param verifyCode - the code the user gets texted to his/her phone
 * @returns - APIReturn<string>
 */
export async function verifyTwilio(
  phoneNumber: string,
  verifyCode: string
): Promise<APIReturn<string>> {
  try {
    const resp = await axios({
      method: 'get',
      url: VERIFY_TWILIO_URL,
      headers: {
        phoneNumber: `+1${phoneNumber}`,
        code: verifyCode,
      },
    });
    return {
      status: resp.status,
      data: resp.data,
    };
  } catch (error) {
    return {
      error: error as string,
      status: 409,
    };
  }
}

//Post expert data to edit Expert Profile
export async function postExpertProfile(
  expertProfile: ExpertProfile
): Promise<APIReturn<ExpertProfile>> {
  try {
    const response = await axios({
      method: 'post',
      url: POST_EXPERT_PROFILE_URL,
      data: expertProfile,
    });

    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error: unknown) {
    return {
      status: 204,
      error: error as string,
    };
  }
}

//Post user data given the onboarding step
export async function sendExpertAPIData(
  data: PostExpertOnboardInput
): Promise<APIReturn<ExpertProfile>> {
  console.log('Expert Data: ', data);
  try {
    const response = await instance.post(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/experts/onboard',
      {
        userId: data.userId,
        stepInput: data.stepInput,
        onboardStep: data.onboardStep,
      }
    );
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error: unknown) {
    return {
      status: 204,
      error: 'Error: sendExpertAPIData',
    };
  }
}

// Get Expert Data
export async function getExpertData(
  userId: string
): Promise<APIReturn<ExpertProfile>> {
  console.log('expert data:', userId);
  try {
    const response = await instance.get(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/experts/profile',
      {
        headers: {
          userId: userId,
        },
      }
    );
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error: unknown) {
    return {
      status: 204,
      error: 'Error: sendExpertAPIData',
    };
  }
}

//Post profile photo
export async function postExpertProfilePhoto(
  data: PostExpertPhotoData
): Promise<APIReturn<any>> {
  console.log('File Data: ', data);
  const photo_instance = axios.create({
    baseURL: 'http://localhost:8000/',
    timeout: 10000,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  try {
    const response = await photo_instance.post(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/storage/upload',
      {
        file: data.fileInput,
        fileName: data.fileName,
      }
    );
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error: unknown) {
    return {
      status: 204,
      error: 'Error: sendExpertProfilePhotoAPIData',
    };
  }
}

export async function putBackStep(
  userId: string
): Promise<APIReturn<ExpertProfile>> {
  try {
    const response = await instance.put(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/experts/onboard/back',
      {},
      { headers: { userId: userId } }
    );
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error: unknown) {
    return {
      error: error as string,
      status: 204,
    };
  }
}

export async function saveExpertAvailability(
  availabilityObj: ExpertAvailabilityPost
): Promise<APIReturn<ExpertAvailability>> {
  try {
    const response = await instance.post(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/schedules/availability/set',
      availabilityObj
    );
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: saveExpertAvailability');
    return {
      status: 204,
      error: 'Error: sendExpertNicheAPIData',
    };
  }
}
