import React, { useState } from 'react';
import './profileChecklist.scss';

import { ReactComponent as GreenCircleCheckSVG } from '../../Assets/SVGs/green-circle-check.svg';
import { ReactComponent as CircleSVG } from '../../Assets/SVGs/circle.svg';

// Types
import {
  CheckListItem,
  ProfileCheckList,
} from '../../Types/profileChecklistTypes';

type Props = {
  displayChecklist: boolean;
  checklist: ProfileCheckList;
  handleGoNow: (item: string) => void;
};

function ProfileChecklist(props: Props): JSX.Element {
  const [displayChecklist, setDisplayCheckList] = useState(
    props.displayChecklist
  );

  // Returns the number of completed checklist items
  function numCompleted(): number {
    let num_completed = 0;
    for (let i = 0; i < props.checklist.list.length; i++) {
      if (props.checklist.list[i].complete == true) {
        num_completed++;
      }
    }
    return num_completed;
  }

  const checklist_text = {
    picture: '1. Upload a Profile Picture',
    bio: '2. Write your Bio',
    credibility: '3. Add your Credentials',
    niches: '4. Create a Niche',
    schedule: '5. Update your availibility',
    payment: '6. Set up Payment',
  };
  // Returns the checklist text mapped to the key passed in
  function mapToRowText(text: string): string {
    return checklist_text[text as keyof typeof checklist_text];
  }

  //   closes checklist
  function handleCloseList(): void {
    setDisplayCheckList(false);
  }

  return (
    <>
      {displayChecklist ? (
        <div className="checklist-container">
          <div className="checklist-header">
            PROFILE CHECKLIST
            <span>
              {' '}
              <div className="completed-num">{numCompleted()}</div> /8 completed
            </span>
          </div>
          {props.checklist.list.map(
            (checklistItem: CheckListItem, index: number) => {
              return (
                <div key={index} className="checklist-row">
                  <div className="checkmark">
                    {checklistItem.complete ? (
                      <GreenCircleCheckSVG />
                    ) : (
                      <CircleSVG />
                    )}
                  </div>
                  <div
                    className={
                      'row-text' +
                      (!checklistItem.complete ? ' incomplete-row-text' : '')
                    }
                  >
                    {mapToRowText(checklistItem.item)}
                  </div>
                  <div className="complete-status">
                    {checklistItem.complete ? (
                      <div className="done-status">done</div>
                    ) : (
                      <div
                        onClick={() => props.handleGoNow(checklistItem.item)}
                        className="go-now-status"
                      >
                        go now
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          )}
          <div className="checklist-footer">
            <div onClick={handleCloseList} className="checklist-footer-text">
              Hide this checklist
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ProfileChecklist;
