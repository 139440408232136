import React, { ReactElement, useEffect, useState } from 'react';
import './expertOnboardingUsername.scss';

import { useOutletContext } from 'react-router-dom';
import { ExpertOnboardingSetters } from '../../../Widgets/ExpertOnboarding/ExpertOnboardingWidget';

function ExpertOnboardingUsername(): ReactElement {
  const { setIsStepComplete, setStepInput, postStatus } =
    useOutletContext<ExpertOnboardingSetters>();

  // State of username
  const [sectionUsername, setSectionUsername] = useState<string>('');

  const isValidUsername = !postStatus ? true : postStatus === 201;

  const isSectionComplete =
    sectionUsername.length > 2 && sectionUsername.length < 21;

  function handleSectionUsername(e: string): void {
    e = e.replace(/\s/g, '');
    setSectionUsername(e);
  }

  useEffect(() => {
    setIsStepComplete(isSectionComplete);
    setStepInput(sectionUsername);
  }, [isSectionComplete, sectionUsername]);

  return (
    <>
      <div className="personal-info-wrapper">
        <div className="header-section">
          <div className="header-text">Your Username</div>
          <div className="required-fields"></div>
        </div>
        <div className="section-description">
          A unique name to represent you on the platform (3 - 20 chrs max)
        </div>
        <div className="info-inputs">
          <div className="username">
            <input
              type="text"
              className={
                'form-control form-control-lg' +
                (isValidUsername ? '' : ' invalid')
              }
              placeholder="Type here..."
              value={sectionUsername}
              onChange={(e) => handleSectionUsername(e.target.value)}
            ></input>
            {!isValidUsername && (
              <div className="invalid-feedback">Username Already Taken</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpertOnboardingUsername;
