import axios from 'axios';
import { ExpertEvent } from '../Types/eventTypes';
import { APIReturn } from '../Types/APITypes';

const instance = axios.create({
  baseURL: 'http://localhost:8000/',
  timeout: 10000,
});

const CHANGE_EVENT_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/schedules/event';

//Send edited data from a user
export async function updateEventState(
  eventId: number,
  state: string
): Promise<APIReturn<ExpertEvent>> {
  console.log('Editing event state: ', eventId, state);
  try {
    const response = await instance.put(CHANGE_EVENT_URL, {
      id: eventId,
      state: state,
    });
    console.log(response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: updateEventState');
    return {
      status: 204,
      error: 'Error: updateEventState',
    };
  }
}
