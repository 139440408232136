import React, { ReactElement, useState } from 'react';
import { TwilioResponses } from '../../../Hooks/types';
import { verifyTwilio } from '../../../Services/expertServices';

function containsOnlyNumbers(str: string): boolean {
  // got from here: https://javascript.plainenglish.io/javascript-check-if-string-contains-only-numbers-a6a5a74a24eb#:~:text=by%20a%20character.-,To%20check%20if%20a%20string%20contains%20only%20numbers%20in%20JavaScript,Otherwise%2C%20it%20will%20return%20false%20.&text=The%20RegExp%20test()%20method,regular%20expression%20and%20a%20string.
  return /^\d+$/.test(str);
}

const CORRECT_CODE_LENGTH = 6;

type Props = {
  phoneNumber: string;
  modalId: string;
  setIsVerified: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setVerificationAttempted: React.Dispatch<React.SetStateAction<boolean>>;
};

function VerificationModal({
  phoneNumber,
  modalId,
  setIsVerified,
  setVerificationAttempted,
  setIsLoading,
}: Props): ReactElement {
  const [verificationCode, setVerificationCode] = useState('');
  const isValidCode =
    verificationCode.length === CORRECT_CODE_LENGTH &&
    containsOnlyNumbers(verificationCode);
  const showInvalidMsg = !!verificationCode && !isValidCode; // don't want the code to yell at user until they start typing

  async function onSubmit(): Promise<void> {
    if (isValidCode) {
      setIsLoading(true);
      const resp = await verifyTwilio(phoneNumber, verificationCode);
      setIsLoading(false);
      setIsVerified(resp.data === TwilioResponses.VALID);
      setVerificationAttempted(true);
    } else {
      console.log('Verification Code Invalid!');
    }
  }

  return (
    <div className="info-inputs">
      <div
        className="modal fade"
        id={modalId}
        tabIndex={-1}
        aria-labelledby="phoneVerifyModalLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="phoneVerifyModalLabel">
                Verify Phone Number
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="modal-body-text">
                {`A verification code has been sent to the following number: ${phoneNumber}`}
              </div>
              <input
                aria-label="verification code input"
                type="text"
                onChange={(e) => setVerificationCode(e.target.value)}
                value={verificationCode}
                className={'form-control' + (showInvalidMsg ? ' invalid' : '')}
              />
              {showInvalidMsg && (
                <div className="invalid-feedback">{`Invalid code: Must be a ${CORRECT_CODE_LENGTH}-character number`}</div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={
                  'btn btn-outline-primary' + (!isValidCode ? ' disabled' : '')
                }
                data-bs-dismiss="modal"
                onClick={onSubmit}
              >
                Submit Code
              </button>
              <div className="modal-body-text" style={{ marginTop: '32px' }}>
                If you did not receive the code, please check that you have
                entered the right number, then try again.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VerificationModal;
