import axios from 'axios';
import { SettingsObject } from '../Types/settingsTypes';
import { APIReturn } from '../Types/APITypes';

const instance = axios.create({
  baseURL: 'https://wetrieve-apis.herokuapp.com/',
  timeout: 10000,
});

const SET_ACTIVE_SETTING_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/settings/active';

const SET_EMAIL_SETTING_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/settings/emailNotifications';

//Update active setting for user
export async function updateActiveSettings(
  expertUserId: string,
  active: boolean
): Promise<APIReturn<SettingsObject>> {
  console.log('updating active settings state: ', expertUserId, active);
  try {
    const response = await instance.put(SET_ACTIVE_SETTING_URL, null, {
      params: { expertUserId, active },
    });
    console.log(response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: updateActiveSettings', error);
    return {
      status: 204,
      error: 'Error: updateActiveSettings',
    };
  }
}

//Update active setting for user
export async function updateEmailSettings(
  expertUserId: string,
  emailNotifications: boolean
): Promise<APIReturn<SettingsObject>> {
  console.log(
    'updating email settings state: ',
    expertUserId,
    emailNotifications
  );
  console.log(typeof expertUserId);
  try {
    const response = await instance.put(SET_EMAIL_SETTING_URL, null, {
      params: {
        expertUserId,
        emailNotifications,
      },
    });
    console.log(response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: updateEmailSettings', error);
    return {
      status: 204,
      error: 'Error: updateEmailSettings',
    };
  }
}
