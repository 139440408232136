import React, { useEffect, useState, ReactElement } from 'react';
import './expertProfile.scss';
import Header from '../../Components/header/header';
// import StarReview from '../../Components/starReview/starReview';
import { ReactComponent as LocationsSVG } from '../../Assets/SVGs/locations.svg';
import { ReactComponent as CheckMarkSVG } from '../../Assets/SVGs/checkmark.svg';
import ProfileNicheList from '../../Components/profileNicheList/profileNicheList';
import ProfileStripeTab from '../../Components/profileStripeTab/profileStripeTab';
import ProfileSettingsTab from '../../Components/profileSettingsTab/profileSettingsTab';
import ProfileCalendarTab from '../../Components/profileCalendarTab/profileCalendarTab';
import ProfileChecklist from '../../Components/profileChecklist/profileChecklist';
import { useProfilePhoto } from '../../Hooks/expertProfilePageHooks';
import { useExpertProfile } from '../../Hooks/useExpertProfile';

type Props = {
  authUserID: string;
  authUserEmail: string;
};

import { ProfileCheckList } from '../../Types/profileChecklistTypes';
import { EditProfileModal } from '../../Components/Modals';

import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { getSVGFromAvatarSeed } from '../../helpers';

export type ReviewScore = {
  reviewScore: number;
};

function ExpertProfile({ authUserID, authUserEmail }: Props): ReactElement {
  const {
    isLoading: isProfileLoading,
    data: expertProfile,
    refetch: refetchExpertProfile,
  } = useExpertProfile(authUserID);

  const [profilePhotoURL, setProfilePhotoURL] = useState<string>('');

  const {
    data: imageBlob,
    isLoading: isProfilePhotoLoading,
    refetch,
  } = useProfilePhoto(expertProfile?.photo || '');

  const avatarSVG = getSVGFromAvatarSeed(expertProfile?.photo || '');

  useEffect(() => {
    if (expertProfile) {
      // If photo is present, fetch it. Else render the avatar
      if (expertProfile.photo?.includes('photoId')) {
        refetch();
      }
    }
  }, [expertProfile]);

  useEffect(() => {
    if (imageBlob) {
      const blob2 = new Blob([imageBlob], { type: 'image/jpeg' });
      const imageObjectURL = URL.createObjectURL(blob2);
      setProfilePhotoURL(imageObjectURL);
    }
  }, [imageBlob]);

  const profileCheckList: ProfileCheckList = {
    list: [
      { item: 'picture', complete: true },
      { item: 'bio', complete: true },
      {
        item: 'credibility',
        complete:
          !!expertProfile?.credibility_1 ||
          !!expertProfile?.credibility_2 ||
          !!expertProfile?.credibility_3,
      },
      { item: 'niches', complete: false },
      { item: 'schedule', complete: false },
      { item: 'payment', complete: false },
    ],
  };

  const displayChecklist = false;

  let localTime = new Date().toLocaleTimeString();
  localTime =
    localTime.slice(0, localTime.length - 6) +
    ' ' +
    localTime.slice(localTime.length - 2);

  const [currentActiveTab, setCurrentActiveTab] = useState<string>('niches');
  function handleNavClick(tab: string) {
    document?.getElementById(currentActiveTab)?.classList.remove('active');
    document?.getElementById(tab)?.classList.add('active');
    setCurrentActiveTab(tab);
  }

  // Function that determines which tab to render
  function renderTab(): JSX.Element {
    switch (currentActiveTab) {
      case 'niches':
        if (expertProfile) {
          return <ProfileNicheList userName={expertProfile.userName} />;
        } else return <></>;
      case 'payment':
        if (expertProfile) {
          return <ProfileStripeTab userId={expertProfile.id} />;
        } else return <></>;
      case 'schedule':
        if (expertProfile) {
          return <ProfileCalendarTab userName={expertProfile.userName} />;
        } else return <></>;
      case 'settings':
        return <ProfileSettingsTab userId={authUserID} />;
    }

    return <></>;
  }

  // Function that handles when credentials is clicked
  function handleCredentialsClick(website?: string): void {
    window.open(website, '_blank');
  }

  // Function that handles the go now value passed back from checklist component
  function handleGoNow(item: string): void {
    console.log('item: ', item);
    if (
      item == 'payment' ||
      item == 'niches' ||
      item == 'schedule' ||
      item == 'settings'
    ) {
      handleNavClick(item);
    }
  }

  return (
    <>
      <Header></Header>
      {isProfileLoading ? (
        <p>Profile Loading...</p>
      ) : (
        <div className="container-lg">
          <div className="profile-container">
            <div className="details-column">
              <div className="profile-header">
                <div className="profile-header-text">My Profile</div>
                <button type="button" className="btn btn-outline-dark btn-sm">
                  View
                </button>

                {isProfileLoading ? (
                  <LoadingSpinner />
                ) : (
                  expertProfile && (
                    <EditProfileModal
                      refetchExpertProfile={refetchExpertProfile}
                      expertProfile={expertProfile}
                      authUserEmail={authUserEmail}
                    />
                  )
                )}
              </div>
              <div className="profile-picture">
                <div className="profile-picture-frame">
                  {isProfilePhotoLoading ? (
                    <p>LOADING PROFILE PHOTO...</p>
                  ) : (
                    <div className="profile-picture-photo">
                      {expertProfile?.photo.includes('photoId') ? (
                        <img
                          src={profilePhotoURL}
                          width="248"
                          height="248"
                          alt=""
                        ></img>
                      ) : (
                        <img src={avatarSVG} />
                      )}
                    </div>
                  )}
                </div>
                <div className="expert-profile-contents">
                  <div className="user-details">
                    <div className="section-header">
                      {expertProfile?.userName}
                    </div>
                    {/* {isAvailableNow ? (
                      <div className="availability-pill">
                        You are available now!
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="reviews">
                      <StarReview reviewScore={3.5}></StarReview>
                      <span>(No reviews yet)</span>
                    </div> */}
                    <div className="local-time">
                      <span>
                        <LocationsSVG />
                      </span>
                      Local Time: {localTime}
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="phone-number">
                    <div className="section-header">Phone Number</div>
                    <div className="phone-number-number">
                      {expertProfile?.phone || ''}
                      <span>Verified</span>{' '}
                      <span id="checkmark">
                        <CheckMarkSVG />
                      </span>
                    </div>
                  </div>
                  <div className="bio">
                    <div className="section-header">Bio</div>
                    <div className="bio-text-frame">{expertProfile?.bio}</div>
                  </div>
                  <div className="websites">
                    <div className="section-header">Credentials</div>
                    <div
                      onClick={() =>
                        handleCredentialsClick(expertProfile?.credibility_1)
                      }
                      className="website"
                    >
                      {expertProfile?.credibility_1}
                    </div>
                    <div
                      onClick={() =>
                        handleCredentialsClick(expertProfile?.credibility_2)
                      }
                      className="website"
                    >
                      {expertProfile?.credibility_2}
                    </div>
                    <div
                      onClick={() =>
                        handleCredentialsClick(expertProfile?.credibility_3)
                      }
                      className="website"
                    >
                      {expertProfile?.credibility_3}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-tabs">
              <ul className="nav nav-tabs">
                <li
                  className="nav-item"
                  onClick={() => handleNavClick('niches')}
                >
                  <a
                    className="nav-link active"
                    id="niches"
                    aria-current="page"
                  >
                    Niches
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavClick('payment')}
                >
                  <a id="payment" className="nav-link">
                    Payment
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavClick('schedule')}
                >
                  <a id="schedule" className="nav-link">
                    Schedule
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handleNavClick('settings')}
                >
                  <a id="settings" className="nav-link">
                    Settings
                  </a>
                </li>
              </ul>
              <div>{renderTab()}</div>
            </div>
            <div className="checklist">
              <ProfileChecklist
                displayChecklist={displayChecklist}
                checklist={profileCheckList}
                handleGoNow={handleGoNow}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ExpertProfile;
