import { GetHook, OnboardingStep } from './types';
import useAPI from './useAPI';

export type ExpertProfile = {
  id: number;
  userId: string;
  userName: string;
  phone: string;
  bio: string;
  credibility_1: string;
  credibility_2: string;
  credibility_3: string;
  photo: string;
  onboard_step: OnboardingStep;
};

const GET_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/experts/profile';

const GET_URL_USERNAME =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/experts/profile/public';

const useExpertProfile: GetHook<ExpertProfile> = (userId) => {
  return useAPI<ExpertProfile>({
    method: 'get',
    url: GET_URL,
    headers: { userId },
  });
};

// Settings Info
const useExpertProfileUsername: GetHook<ExpertProfile> = (userName) => {
  console.log('in useExpertProfileUsername hook with username: ', userName);
  const res = useAPI<ExpertProfile>({
    method: 'get',
    url: GET_URL_USERNAME,
    headers: { userName: userName },
  });
  console.log('res: ', res);
  return res;
};

export { useExpertProfile, useExpertProfileUsername };
