/* eslint-disable @typescript-eslint/no-unused-vars */ // TEMPORARY FOR DRAFT PR
import React, { ReactElement, useState } from 'react';
import { Button, Modal, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import EmptyPhotoSVG from '../../Assets/SVGs/emptyPhoto.svg';
import './EditProfileModal.scss';
import { ExpertProfile } from '../../Hooks/useExpertProfile';
import { DividerLine } from '../DividerLine';
import { UserProfile } from '../../Types/userTypes';
import { getSVGFromAvatarSeed } from '../../helpers';
import { postExpertProfile } from '../../Services/expertServices';
import { LoadingSpinner } from '../LoadingSpinner';

/*

TODO:

- Make sure phone number is verified
- Finish Edit profile picture

*/

type EditProfileModalProps = {
  authUserEmail: string;
  expertProfile: ExpertProfile;
  refetchExpertProfile: () => void;
};

export const EditProfileModal = ({
  authUserEmail,
  expertProfile,
  refetchExpertProfile,
}: EditProfileModalProps): ReactElement => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const avatarSVG = getSVGFromAvatarSeed(expertProfile.photo);

  const { handleSubmit, register, reset } = useForm<
    ExpertProfile & UserProfile
  >({
    defaultValues: { ...expertProfile, email: authUserEmail },
  });

  const onSubmit = async (data: ExpertProfile & UserProfile) => {
    setIsLoading(true);
    const { data: postReturn, error } = await postExpertProfile(data);
    setIsLoading(false);

    if (postReturn) {
      refetchExpertProfile();
      setShow(false);
    } else {
      confirm(error);
    }

    console.log('FORM DATA: ' + data);
  };

  function onModalClose() {
    reset({ ...expertProfile, email: authUserEmail });
    setShow(false);
  }

  function handlePhotoChange(): void {
    // do nothing
  }

  return (
    <>
      <Button variant="outline-primary" onClick={() => setShow(true)}>
        Edit
      </Button>

      <Modal show={show} onHide={onModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editing your profile</Modal.Title>
        </Modal.Header>
        {/* <form onSubmit={handleSubmit(onSubmit)}>  THIS IS HOW IT SHOULD BE IF OPERATIONAL */}
        <form onSubmit={onModalClose}>
          <Modal.Body>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="mb-3">
                  <label className="form-label">Profile Picture</label>
                  <div className="profilePicture">
                    <Image
                      onClick={handlePhotoChange}
                      src={avatarSVG}
                      alt={EmptyPhotoSVG}
                      fluid
                    />
                    {/* <p onClick={handlePhotoChange}>Edit</p> */}
                  </div>
                </div>
                <DividerLine />
                <div className="mb-3 phoneNumber">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={expertProfile.phone}
                    {...(register('phone'), { minLength: 14 })}
                  />
                </div>
                <DividerLine />
                <div className="mb-3 userDetails">
                  <label className="form-label">User Details</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    {...register('email')}
                  />
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={expertProfile.userName}
                    {...(register('userName'), { required: true })}
                  />
                </div>
                <DividerLine />
                <div className="mb-3 credentials">
                  <label className="form-label">Credentials</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register('credibility_1')}
                  />
                  <input
                    type="text"
                    className="form-control"
                    {...register('credibility_2')}
                  />
                  <input
                    type="text"
                    className="form-control"
                    {...register('credibility_3')}
                  />
                </div>
                <DividerLine />
                <div className="mb-3 bio">
                  <label className="form-label">Bio</label>
                  <textarea
                    className="form-control"
                    rows={5}
                    {...register('bio')}
                  />
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="footerCustom">
            <button
              type="button"
              className="btn btn-outline-secondary "
              onClick={onModalClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-outline-primary">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditProfileModal;
