import React, { ReactElement } from 'react';
import './customAgendaDate.scss';

type Props = {
  day: Date;
  label: string;
};

function CustomWeekEvent(props: Props): ReactElement {
  const dayText = props.label.split(' ')[0];
  const monthText = props.label.split(' ')[1];
  const dayNumber = props.label.split(' ')[2];

  return (
    <>
      <div className="agenda-date-container">
        <div className="agenda-date-content">
          <div className="month-with-number">{monthText + ' ' + dayNumber}</div>
          <div className="day-text">{dayText}</div>
        </div>
      </div>
    </>
  );
}

export default CustomWeekEvent;
