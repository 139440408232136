import axios from 'axios';
import { StripeAccountLinkObject } from '../Types/stripeTypes';
import { APIReturn } from '../Types/APITypes';

const instance = axios.create({
  baseURL: 'https://wetrieve-apis.herokuapp.com/',
  timeout: 10000,
});

const CREATE_STRIPE_ACCOUNT_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/stripe/profile';

//Update active setting for user
export async function createStripeAccount(
  expertId: number
): Promise<APIReturn<StripeAccountLinkObject>> {
  try {
    const response = await instance.post(CREATE_STRIPE_ACCOUNT_URL, null, {
      params: { expertId },
    });
    console.log(response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: updateActiveSettings', error);
    return {
      status: 204,
      error: 'Error: updateActiveSettings',
    };
  }
}
