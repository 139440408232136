import React, { ReactElement } from 'react';
import './customWeekEvent.scss';
import { CalendarEvent } from '../profileCalendarTypes';
import AppointmentPopout from '../../appointmentPopout/appointmentPopout';

type Props = {
  event: CalendarEvent;
};

function CustomWeekEvent(props: Props): ReactElement {
  const withPmAmTime = props.event.start.toLocaleTimeString('en-US', {
    // en-US can be set to 'default' to use user's browser settings
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <>
      <div className={'week-event-container ' + props.event.type}>
        <div
          className="week-event"
          data-bs-toggle="modal"
          data-bs-target={'#appointmentPopoutModal' + '-' + props.event.id}
        >
          <div className="week-event-name">{props.event.title}</div>
          <div className="week-event-time">{withPmAmTime}</div>
        </div>
      </div>
      <div
        className="modal fade"
        id={'appointmentPopoutModal' + '-' + props.event.id}
        tabIndex={-1}
        aria-labelledby={'#appointmentPopoutModal' + '-' + props.event.id}
        aria-hidden="true"
      >
        <AppointmentPopout event={props.event} />
      </div>
    </>
  );
}

export default CustomWeekEvent;
