import React, { useState } from 'react';
import './profileNicheList.scss';
import { ReactComponent as ToggleDotsSVG } from '../../Assets/SVGs/toggle-dots.svg';
import { ReactComponent as QuestionMarkCircleSVG } from '../../Assets/SVGs/question-mark-circle.svg';
import { ReactComponent as EditSVG } from '../../Assets/SVGs/edit.svg';
import { ReactComponent as DeleteSVG } from '../../Assets/SVGs/delete.svg';
import { ReactComponent as XSVG } from '../../Assets/SVGs/x.svg';
import {
  sendExpertNicheAPIData,
  sendEditedNicheAPIData,
  deleteNicheData,
} from '../../Services/expertNichesServices';
import { useExpertNiches } from '../../Hooks/expertProfilePageHooks';

import StarReview from '../starReview/starReview';

import ReactTooltip from 'react-tooltip';

// Types
import {
  ProfileNicheCard,
  ProfileNicheCards,
} from '../../Types/nicheCardTypes';

type Props = {
  userName: string;
};

function mapNiches(rawNiches?: ProfileNicheCards): ProfileNicheCards {
  // Populate the ProfileNicheCards object with data returned from API
  const niches: ProfileNicheCards = [];

  if (!rawNiches) {
    return niches;
  }

  for (const niche of rawNiches) {
    if (niche['tags'] == '') {
      const updatedDataNiches = {
        ...niche,
        tags: '',
      };
      niches?.push(updatedDataNiches);
    } else {
      const updatedDataNiches = {
        ...niche,
        tags: niche['tags'],
      };
      niches?.push(updatedDataNiches);
    }
  }
  return niches;
}

// Props being passed from 'expertProfile.tsx' file
function ProfileNicheList(props: Props): JSX.Element {
  const {
    isLoading: isNichesLoading,
    data: rawNiches,
    refetch: refetchNiches,
  } = useExpertNiches(props.userName);

  const niche_cards = mapNiches(rawNiches);

  // Tag management
  const [tags, setTags] = useState<Array<string>>([]);
  const [currentTagValue, setCurrentTagValue] = useState<string>('');
  function handleTagInput(value: string) {
    value = value.replace(/\s/g, '');
    setCurrentTagValue(value);
  }

  // If user presses enter when creating a tag, add it to list
  function handleTagInputKey(key: string): void {
    if (key === 'Enter') {
      addTag();
    }
  }

  function removeTag(index: number): void {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    updateSectionStatus();
  }

  function addTag(): void {
    if (
      tags.length < 3 &&
      currentTagValue != '' &&
      currentTagValue.length < 25
    ) {
      setTags([...tags, currentTagValue]);
      setCurrentTagValue('');
      (document.getElementById('tagInput') as HTMLInputElement).value = '';
    }
    updateSectionStatus();
  }
  // End of Tag management ------

  //   State value that keeps track is everything in niche modal is filled out
  const [nicheCompleteStatus, setNicheCompleteStatus] =
    useState<boolean>(false);

  //   Called whenever one of the form fields in the create niche modal are updated
  function updateSectionStatus(): void {
    if (nicheName.length >= 2 && nicheDescription.length >= 10) {
      setNicheCompleteStatus(true);
    } else {
      setNicheCompleteStatus(false);
    }
  }

  //   Called when user writes in niche name field
  const [nicheName, setNicheName] = useState<string>('');
  function handleNicheName(e: string): void {
    setNicheName(e);
    updateSectionStatus();
  }

  //   Called when user writes in niche name field
  const [nicheDescription, setNicheDescription] = useState<string>('');
  function handleNicheDescription(e: string): void {
    setNicheDescription(e);
    updateSectionStatus();
  }

  //   Called when Payrate field is changed
  const [nichePayRate, setNichePayRate] = useState<number>(0);
  function handlePayRate(e: number): void {
    setNichePayRate(e);
    updateSectionStatus();
  }

  //   Called when Payrate field is changed
  const [nicheDynamicPayRate, setNicheDynamicPayRate] = useState<number>(0);
  function handleDynamicPayRate(e: number): void {
    setNicheDynamicPayRate(e);
    updateSectionStatus();
  }

  const [nicheCreateResponse, setNicheCreateResponse] =
    useState<string>('awaiting');

  //   Called when user clicks create button
  async function handleCreate(): Promise<void> {
    const response = await sendExpertNicheAPIData(bodyParameters);
    if (response?.status == 201) {
      document.getElementById('modal-x')?.click();
      setNicheCreateResponse('success');
      refetchNiches();
    } else {
      setNicheCreateResponse('error');
    }
  }

  // Called When User Edits a Niche
  async function handleEdit(): Promise<void> {
    const response = await sendEditedNicheAPIData(bodyParameters);
    if (response?.status == 200) {
      console.log('niche edited successfully');
      document.getElementById('modal-x')?.click();
      setNicheCreateResponse('success');
      refetchNiches();
    } else {
      setNicheCreateResponse('error');
    }
  }

  // Called When User Deletes a Niche
  async function handleDelete(nicheName: string): Promise<void> {
    const response = await deleteNicheData(props.userName, nicheName);
    if (response?.status == 200) {
      console.log('niche deleted successfully');
      document.getElementById('confirmDeleteModal')?.click();
      refetchNiches();
    }
  }

  // On Edit Niche
  const [inEditNicheMode, setInEditNicheMode] = useState<boolean>(false);
  function onEditNiche(niche_card: ProfileNicheCard): void {
    // console.log('niche_card: ', niche_card);
    setNicheCompleteStatus(false);
    setNicheName(niche_card.name);
    setNicheDescription(niche_card.description);
    setNichePayRate(niche_card.flatRate);
    setNicheDynamicPayRate(niche_card.dynamicRate);
    console.log('niche_card.tags[0]: ', niche_card.tags[0]);
    console.log('tags[0]: ', tags[0]);
    setTags(niche_card.tags.toString().split(','));
    setInEditNicheMode(true);
  }

  // On Add Niche
  function onAddNiche() {
    setNicheName('');
    setNicheDescription('');
    setNichePayRate(0);
    setNicheDynamicPayRate(0);
    setTags([]);
    setInEditNicheMode(false);
  }

  const [deleteNicheName, setDeleteNicheName] = useState<string>('');

  // function that deletes niche
  function onDeleteNiche(): void {
    console.log('Deleting niche....', deleteNicheName);
    handleDelete(deleteNicheName);
  }

  // Requests for back end when user clicks Create button
  const bodyParameters = {
    name: nicheName,
    description: nicheDescription,
    flatRate: isNaN(nichePayRate) ? 0 : nichePayRate,
    dynamicRate: nicheDynamicPayRate,
    tags: String(tags),
    userName: props.userName,
  };
  // -------------------------

  return (
    <>
      {isNichesLoading ? (
        <div className="spinner-border text-primary" role="status"></div>
      ) : (
        <>
          {niche_cards.length == 0 ? (
            <div className="niches-container">
              <div className="no-niches-container-contents">
                <div className="no-niche-text">
                  You do not have any Niches, click to add one.
                </div>
                <div className="add-niche-button">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#addNicheModal"
                    onClick={() => onAddNiche()}
                  >
                    ADD NICHE
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="niche-tab">
                <div className="no-niches-header">
                  My Niches
                  <ReactTooltip />
                  <span data-tip="Niches you are an expert on">
                    <QuestionMarkCircleSVG />
                  </span>
                </div>
              </div>
              {niche_cards.map((niche_card: ProfileNicheCard) => {
                return (
                  <div key={niche_card.name} className="niches-container">
                    <div className="niche-header-section">
                      <div className="niche-header-text">{niche_card.name}</div>
                      <div className="dropdown">
                        <div
                          className="niche-header-button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <ToggleDotsSVG />
                        </div>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#addNicheModal"
                              onClick={() => onEditNiche(niche_card)}
                            >
                              <span>
                                <EditSVG />
                              </span>
                              Edit
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#confirmDeleteModal"
                              onClick={() => {
                                setDeleteNicheName(niche_card.name);
                              }}
                            >
                              <span>
                                <DeleteSVG />
                              </span>
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="niche-content-section">
                      <div className="niche-content-description-tags">
                        <div className="niche-content-descrition">
                          {niche_card.description}
                        </div>
                        <div className="niche-content-tags">
                          {niche_card.tags[0] === undefined ? (
                            <></>
                          ) : (
                            niche_card.tags
                              .toString()
                              .split(',')
                              .map((niche_tag, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="niche-content-tag"
                                  >
                                    <div className="niche-content-tag-text">
                                      {niche_tag.toUpperCase()}
                                    </div>
                                  </div>
                                );
                              })
                          )}
                        </div>
                      </div>
                      <div className="niche-content-rate-reviews">
                        <div className="rate-per-time">
                          <div className="rate-per-time-text">
                            Flat Rate: ${niche_card.flatRate}
                          </div>
                        </div>
                        <div className="number-of-calls">
                          <div className="number-of-calls-text">
                            {niche_card.numCalls}{' '}
                            {niche_card.numCalls == 1 ? 'Call' : 'Calls'}
                          </div>
                        </div>
                        <div className="niche-reviews">
                          <div className="niche-review-stars">
                            <StarReview
                              reviewScore={niche_card.rating}
                            ></StarReview>
                          </div>
                          <div className="niche-number-reviews">
                            {niche_card.numReviews} Reviews
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {niche_cards.length < 3 ? (
                <div
                  className="add-niche-container"
                  data-bs-toggle="modal"
                  data-bs-target="#addNicheModal"
                >
                  <div className="add-niche-text" onClick={() => onAddNiche()}>
                    Add Another Niche +
                  </div>
                </div>
              ) : (
                <div className="add-niche-container">
                  <div className="add-niche-text">Maximum Niches Reached</div>
                </div>
              )}
            </>
          )}
          <div
            className="modal fade"
            id="confirmDeleteModal"
            tabIndex={-1}
            aria-labelledby="confirmDeleteModal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm" id="confirmDeleteModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="confirmDeleteModal">
                    Deleting Niche
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    id="confirmDeleteModal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to delete this Niche? This action
                  cannont be undone.
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-dismiss="modal"
                    id="confirmDeleteModal"
                  >
                    NO
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    type="submit"
                    onClick={onDeleteNiche}
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="addNicheModal"
            tabIndex={-1}
            aria-labelledby="addNicheModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="addNicheModalLabel">
                    {inEditNicheMode ? 'Edit Niche' : 'Create Niche'}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    id="modal-x"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modal-body-text">
                    Your niche is the expertise you will be providing to other
                    learners in the WeTrieve community. Fill out the fields
                    below, then click create.
                  </div>
                  <div className="modal-form-section">
                    <form>
                      <div className="niche-modal-section">
                        <label className="form-label ">
                          Niche Name <ReactTooltip />
                          <span data-tip="Name others will see">
                            <QuestionMarkCircleSVG />
                          </span>
                        </label>
                        <div className="input-group input-group-lg">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg"
                            placeholder="Type name here..."
                            maxLength={30}
                            onChange={(e) => handleNicheName(e.target.value)}
                            value={nicheName}
                            disabled={inEditNicheMode}
                          />
                        </div>
                        <label className="form-label">
                          Short Description <ReactTooltip />
                          <span data-tip="Describe what the niche is!">
                            <QuestionMarkCircleSVG />
                          </span>
                        </label>
                        <div className="input-group input-group-lg">
                          <textarea
                            maxLength={280}
                            className="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg"
                            placeholder="Type description here..."
                            onChange={(e) =>
                              handleNicheDescription(e.target.value)
                            }
                            value={nicheDescription}
                          />
                        </div>
                        <label className="form-label">
                          Tags (Optional) <ReactTooltip />
                          <span data-tip="Words / phrases related to the niche">
                            <QuestionMarkCircleSVG />
                          </span>
                        </label>
                        <div className="add-tag">
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              <div className="niche-content-tags">
                                {tags[0] != '' ? (
                                  tags.map((element, index) => (
                                    <div className="tag-with-x" key={index}>
                                      <div className="niche-content-tag">
                                        <div className="niche-content-tag-text">
                                          {element.toUpperCase()}
                                        </div>
                                        <div className="circle-x-remove">
                                          <XSVG
                                            onClick={() => removeTag(index)}
                                          ></XSVG>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </div>
                            </span>
                            <input
                              id="tagInput"
                              type="text"
                              className="form-control form-control-lg"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-lg"
                              placeholder="Hit enter to add..."
                              maxLength={24}
                              onChange={(e) => handleTagInput(e.target.value)}
                              onKeyPress={(e) => handleTagInputKey(e.key)}
                            ></input>
                          </div>
                        </div>
                        <hr></hr>
                        <label className="form-label">
                          Flate Rate (Optional) <ReactTooltip />
                          <span data-tip="Flat rate of the call">
                            <QuestionMarkCircleSVG />
                          </span>
                        </label>
                        <div className="payrate-input">
                          <div className="payrate-amount">
                            <div className="input-group input-group-lg">
                              <span className="input-group-text">$</span>
                              <input
                                type="number"
                                className="form-control"
                                aria-label="Amount (to the nearest dollar)"
                                placeholder="0"
                                onChange={(e) =>
                                  handlePayRate(e.target.valueAsNumber)
                                }
                                value={nichePayRate || ''}
                              />
                            </div>
                          </div>
                        </div>
                        <label className="form-label">
                          Dynamic Rate (Optional) <ReactTooltip />
                          <span data-tip="Additional charge per minute (usually around 1$)">
                            <QuestionMarkCircleSVG />
                          </span>
                        </label>
                        <div className="payrate-input">
                          <div className="payrate-amount">
                            <div className="input-group input-group-lg">
                              <span className="input-group-text">$</span>
                              <input
                                type="number"
                                className="form-control"
                                aria-label="Amount (to the nearest dollar)"
                                placeholder="0"
                                onChange={(e) =>
                                  handleDynamicPayRate(e.target.valueAsNumber)
                                }
                                value={nicheDynamicPayRate || ''}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="navigation-buttons">
                    <button
                      className="btn btn-outline-dark"
                      type="button"
                      data-bs-dismiss="modal"
                    >
                      CANCEL
                    </button>
                    {inEditNicheMode ? (
                      <button
                        className={
                          'btn btn-outline-primary' +
                          (nicheCompleteStatus ? ' ' : ' disabled')
                        }
                        type="submit"
                        onClick={handleEdit}
                      >
                        SAVE
                      </button>
                    ) : (
                      <button
                        className={
                          'btn btn-outline-primary' +
                          (nicheCompleteStatus ? ' ' : ' disabled')
                        }
                        type="submit"
                        onClick={handleCreate}
                      >
                        CREATE
                      </button>
                    )}
                  </div>
                  {nicheCreateResponse == 'error' ? (
                    <div className="niche-create-error">
                      Oops, something went wrong
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProfileNicheList;
