import React, { ReactElement } from 'react';
import './customAgendaEvent.scss';
import { CalendarEvent } from '../profileCalendarTypes';
import { ReactComponent as AvatarSVG } from '../../../Assets/SVGs/avatar.svg';
import { ReactComponent as DownChevronSVG } from '../../../Assets/SVGs/down-chevron.svg';
import { updateEventState } from '../../../Services/eventsServices';

type Props = {
  event: CalendarEvent;
};

function CustomAgendaEvent(props: Props): ReactElement {
  const withPmAmTimeStart = props.event.start.toLocaleTimeString('en-US', {
    // en-US can be set to 'default' to use user's browser settings
    hour: '2-digit',
    minute: '2-digit',
  });

  const withPmAmTimeEnd = props.event.end.toLocaleTimeString('en-US', {
    // en-US can be set to 'default' to use user's browser settings
    hour: '2-digit',
    minute: '2-digit',
  });

  //   console.log('props: ', props, withPmAmTimeStart);
  function getStatusText(eventType: string): string {
    if (eventType === 'past') {
      return 'Complete';
    } else if (eventType === 'appointment') {
      return 'Accepted';
    } else {
      return 'Pending';
    }
  }

  async function handleDeclineRequest(eventId: string): Promise<void> {
    console.log('declining event with id: ', eventId);
    const response = await updateEventState(Number(eventId), 'DECLINED');
    if (response?.status == 200) {
      console.log('event declined successfully');
    } else {
      console.log(response?.error);
      console.log('Error in handleDeclineRequest');
    }
  }

  async function handleAcceptRequest(eventId: string): Promise<void> {
    console.log('accepting event with id: ', eventId);
    const response = await updateEventState(Number(eventId), 'ACCEPTED');
    if (response?.status == 200) {
      console.log('event accepted successfully');
    } else {
      console.log(response?.error);
      console.log('Error in handleAcceptRequest');
    }
  }

  async function handleNewTimeRequest(eventId: string): Promise<void> {
    console.log('requesting new time event with id: ', eventId);
    const response = await updateEventState(
      Number(eventId),
      'REQUEST_NEW_TIME'
    );
    if (response?.status == 200) {
      console.log('event requesting new time successfully');
    } else {
      console.log(response?.error);
      console.log('Error in handleNewTimeRequest');
    }
  }

  async function handleCancelRequest(eventId: string): Promise<void> {
    console.log('cancelling new time event with id: ', eventId);
    const response = await updateEventState(Number(eventId), 'CANCELLED');
    if (response?.status == 200) {
      console.log('event cancelled successfully');
    } else {
      console.log(response?.error);
      console.log('Error in handleCancelRequest');
    }
  }

  function renderFooter(eventType: string): JSX.Element {
    switch (eventType) {
      case 'past':
        return (
          <>
            <div className="completed-appointment-footer">
              Completed Appointment
            </div>
          </>
        );
      case 'appointment':
        return (
          <>
            <div className="footer-actions">
              <div className="footer-options">
                <div className="footer-options-links">
                  <div
                    className="footer-options-link"
                    onClick={() => handleDeclineRequest(props.event.id)}
                  >
                    Decline Request
                  </div>
                  <div className="footer-options-link-text">or</div>
                  <div
                    className="footer-options-link"
                    onClick={() => handleNewTimeRequest(props.event.id)}
                  >
                    Request New time
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 'request':
        return (
          <>
            <div className="footer-actions">
              <div className="decline-button">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-sm"
                  onClick={() => handleCancelRequest(props.event.id)}
                >
                  DECLINE
                </button>
              </div>
              <div className="accept-button">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => handleAcceptRequest(props.event.id)}
                >
                  ACCEPT
                </button>
              </div>
              <div className="request-new-time-dropdown">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <DownChevronSVG />
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleNewTimeRequest(props.event.id)}
                    >
                      Request New Time
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      <div className="custom-agenda-event-wrapper">
        <div className={'custom-agenda-event-header ' + props.event.type}>
          <div className={'dot-status ' + props.event.type}></div>
          <div className="start-end-time">
            {withPmAmTimeStart} - {withPmAmTimeEnd}
          </div>
          <div className={'status-block ' + props.event.type}>
            {getStatusText(props.event.type)}
          </div>
        </div>
        <div className="custom-agenda-event-body">
          <div className="appointment-with-section">
            <div className="appointment-with-section-header">
              Appointment With
            </div>
            <div className="appointment-with-section-user">
              <div className="appointment-with-section-avatar">
                <AvatarSVG />
              </div>
              <div className="appointment-with-section-name">
                {props.event.learner}
              </div>
            </div>
          </div>
          <div className="niche-info-section">
            <div className="niche-info-section-header">Niche</div>
            <div className="niche-info-section-body">{props.event.title}</div>
          </div>
        </div>
        <div className="custom-agenda-event-footer">
          {renderFooter(props.event.type)}
        </div>
      </div>
    </>
  );
}

export default CustomAgendaEvent;
