import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';

// Pages
import Homepage from './Pages/homepage/homepage';
import { ExpertOnboardingAbout } from './Pages/ExpertOnboarding';
// import ExpertOnboardingPersonalInfo from './Pages/expertOnboardingPersonalInfo/expertOnboardingPersonalInfo';
// import ExpertOnboardingAvatar from './Pages/expertOnboardingAvatar/expertOnboardingAvatar';
import ExpertOnboardingUsername from './Pages/ExpertOnboarding/Username/expertOnboardingUsername';
import ExpertOnboardingPhoneNumber from './Pages/ExpertOnboarding/PhoneNumber/expertOnboardingPhoneNumber';
import ExpertOnboardingBio from './Pages/ExpertOnboarding/Bio/expertOnboardingBio';
import ExpertOnboardingCredentials from './Pages/ExpertOnboarding/Credentials/expertOnboardingCredentials';
import ExpertOnboardingProfilePicture from './Pages/ExpertOnboarding/ProfilePicture/expertOnboardingProfilePicture';
import SearchResults from './Pages/searchResults/searchResults';
import AboutPage from './Pages/AboutPage/aboutPage';

import ExpertProfile from './Pages/expertProfile/expertProfile';

import RequireAuth from './Components/requireAuth/requireAuth';

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import ExpertOnboardingWidget from './Widgets/ExpertOnboarding/ExpertOnboardingWidget';
import { updateUserData } from './helpers';

export enum AllRoutes {
  ABOUT = '/expertOnboarding/about',
  USERNAME = '/expertOnboarding/username',
  PHONENUMBER = '/expertOnboarding/phoneNumber',
  BIO = '/expertOnboarding/bio',
  CREDIBILITY = '/expertOnboarding/credentials',
  PHOTO = '/expertOnboarding/profilePicture',
  HOME = '/',
  COMPLETE = '/expertProfile',
  SEARCH = '/search',
  GENERIC_ABOUT = '/about',
}

function App() {
  const { user, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && user) {
      updateUserData(user);
    }
  }, [user]);

  return (
    <div className="App">
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <Routes>
        <Route path={AllRoutes.HOME} element={<Homepage />} />
        <Route path={AllRoutes.ABOUT} element={<ExpertOnboardingAbout />} />
        <Route // PROTECTED ROUTE
          path="/expertOnboarding/"
          element={
            <RequireAuth>
              <ExpertOnboardingWidget authUser={user?.sub || ''} />
            </RequireAuth>
          }
        >
          <Route path="username" element={<ExpertOnboardingUsername />} />
          <Route path="phoneNumber" element={<ExpertOnboardingPhoneNumber />} />
          <Route path="bio" element={<ExpertOnboardingBio />} />
          <Route path="credentials" element={<ExpertOnboardingCredentials />} />
          <Route
            path="profilePicture"
            element={<ExpertOnboardingProfilePicture />}
          />
        </Route>
        <Route // PROTECTED ROUTE
          path="/expertProfile"
        >
          <Route
            path=":expertProfileID"
            element={
              <RequireAuth>
                <ExpertProfile
                  authUserID={user?.sub || ''}
                  authUserEmail={user?.email || ''}
                />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={AllRoutes.SEARCH} element={<SearchResults />}></Route>
        <Route path={AllRoutes.GENERIC_ABOUT} element={<AboutPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
