import React, { useState, useEffect } from 'react';
import './profileStripe.scss';
import { ReactComponent as QuestionMarkCircleSVG } from '../../Assets/SVGs/question-mark-circle.svg';
import ReactTooltip from 'react-tooltip';
import { useExpertStripe } from '../../Hooks/expertProfilePageHooks';
import { createStripeAccount } from '../../Services/expertStripeServices';
type Props = {
  userId: number;
};

function ProfileStripeTab({ userId }: Props): JSX.Element {
  const {
    isLoading: isStripeLoading,
    data: stripeInfo,
    status: stripeStatus,
  } = useExpertStripe(userId.toString());
  const [isStripeOnboarded, setIsStripeOnboarded] = useState<boolean>(false);
  console.log(isStripeLoading, stripeInfo);

  useEffect(() => {
    if (stripeStatus) {
      if (stripeStatus == 200) {
        setIsStripeOnboarded(true);
      } else {
        setIsStripeOnboarded(false);
      }
    }
  }, [stripeInfo, stripeStatus]);

  async function handleStripeConnect() {
    const stripeAccountResponse = await createStripeAccount(userId);
    if (stripeAccountResponse.status === 200) {
      console.log('account created succesfully');
      if (stripeAccountResponse.data) {
        window.location.href =
          stripeAccountResponse.data.stripeConnectedAccountLink;
      }
    } else {
      console.log('error in account created', stripeAccountResponse.error);
    }
  }

  function handleStripeRetrieveAccount() {
    if (stripeInfo) {
      window.open(stripeInfo.stripeConnectedAccountLink, '_blank');
    }
  }

  return (
    <>
      <div className="niche-tab">
        <div className="no-niches-header">
          Payment Details
          <ReactTooltip />
          <span data-tip="Link To Payment Info">
            <QuestionMarkCircleSVG />
          </span>
        </div>
      </div>
      {isStripeLoading ? (
        <div className="spinner-border text-primary" role="status"></div>
      ) : (
        <>
          {isStripeOnboarded ? (
            <>
              <div className="stripe-setup-container">
                <div className="stripe-container">
                  <div className="no-stripe-container-contents">
                    <div className="stripe-img">
                      <img src={require('../../Assets/JPGs/stripe-logo.jpg')} />
                    </div>
                    <div className="no-stripe-text">
                      Navigate to your Stripe account to view your payment
                      dashboard
                    </div>
                    <div className="add-stripe-button">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleStripeRetrieveAccount}
                      >
                        STRIPE ACCOUNT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="stripe-setup-container">
              <div className="stripe-container">
                <div className="no-stripe-container-contents">
                  <div className="stripe-img">
                    <img src={require('../../Assets/JPGs/stripe-logo.jpg')} />
                  </div>
                  <div className="no-stripe-text">
                    Set up your payment information with Stripe to start making
                    money!
                  </div>
                  <div className="add-stripe-button">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={handleStripeConnect}
                    >
                      CONNECT NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ProfileStripeTab;
