import React, { useEffect, ChangeEvent, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ExpertOnboardingSetters } from '../../../Widgets/ExpertOnboarding/ExpertOnboardingWidget';
import './expertOnboardingProfilePicture.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { getSVGFromAvatarSeed } from '../../../helpers';

function ExpertOnboardingProfilePicture() {
  const { setIsStepComplete, setStepInput, setFileInput } =
    useOutletContext<ExpertOnboardingSetters>();

  // // Conditionally show the login or logout button if user is authenticated
  const { user } = useAuth0();

  // Form checker -------
  const [avatarChecked, setAvatarChecked] = useState<boolean>(false);

  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [previewURL, setPreviewURL] = useState<string>('');
  const avatarSVG = getSVGFromAvatarSeed('seed-' + makeAvatarSeed(10));

  function handleFileUpload(event: ChangeEvent<HTMLInputElement>): void {
    setIsImageUploaded(true);
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setPreviewURL(URL.createObjectURL(file));
    setSelectedFile(file);
    setAvatarChecked(false);
  }

  // Generates a random seed to generate an avatar
  function makeAvatarSeed(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleDefaultAvatarCheckbox() {
    setAvatarChecked(!avatarChecked);
  }

  useEffect(() => {
    if (isImageUploaded && !avatarChecked) {
      setStepInput('photoId-' + user?.['sub']);
    } else {
      setStepInput('seed-' + makeAvatarSeed(10));
    }
    setFileInput(selectedFile);
    setIsStepComplete(isImageUploaded || avatarChecked);
  }, [isImageUploaded, avatarChecked, selectedFile]);

  return (
    <>
      <div className="personal-info-wrapper">
        <div className="header-section">
          <div className="header-text">Your Profile Picture</div>
        </div>
        <div className="section-description">
          Select an image to represent your profile within the WeTrieve
          community. You may use a default animal Avatar instead, if you like.
        </div>
        <div className="photo-upload-container">
          <div className="upload-instructions-text">
            <p>Drag and Drop File Here</p>
            <p>__ __ __</p>
            <p>Or click the upload button</p>
          </div>
          <input
            className="form-control"
            accept="image/png, image/gif, image/jpeg"
            type="file"
            id="upload-img"
            onChange={handleFileUpload}
          ></input>
          <p className="file-type-text">JPG, JPEG, PNG</p>
        </div>
        <div className="avatar-checkbox">
          <input
            className="form-check-input"
            type="checkbox"
            checked={avatarChecked}
            id="flexCheckDefault"
            onChange={handleDefaultAvatarCheckbox}
          ></input>
          <div className="avatar-text">Use generated Avatar instead</div>
        </div>
        <div className="photo-preview">
          <div className="photo-preview-header">
            {previewURL != '' || avatarChecked === true ? <>Preview:</> : <></>}
          </div>
          <div className="photo-preview-photo">
            {avatarChecked === true ? (
              <img src={avatarSVG} />
            ) : (
              <>
                {previewURL != '' ? (
                  <img
                    id="blah"
                    src={previewURL}
                    alt="your image"
                    height="160px"
                    width="160px"
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpertOnboardingProfilePicture;
