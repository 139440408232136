import React, { useState } from 'react';
import './searchResults.scss';
import SearchResultsGrid from '../../Components/searchResultsGrid/searchResultsGrid';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as SearchIconSVG } from '../../Assets/SVGs/search.svg';
import { ReactComponent as XSVG } from '../../Assets/SVGs/circle-x.svg';
import { ReactComponent as DownChevronSVG } from '../../Assets/SVGs/down-chevron-large.svg';
import Header from '../../Components/header/header';
import { useNavigate } from 'react-router-dom';

enum Filter {
  RELEVANCE = 'RELEVANCE',
  PRICE_LOW_HIGH = 'PRICE_LOW_HIGH',
  PRICE_HIGH_LOW = 'PRICE_HIGH_LOW',
}

function SearchResults() {
  const [searchParams] = useSearchParams();
  const [sortByValue, setSortByValue] = useState<string>('');
  const [searchDataQuery, setSearchDataQuery] = useState<string>(
    searchParams.get('query') || ''
  );
  const [filterType, setFilterType] = useState<Filter>(Filter.RELEVANCE);
  console.log('filterType: ', filterType);

  const navigate = useNavigate();

  function handleSearchInputEvent(key: string): void {
    if (key === 'Enter') {
      handleSearch();
    }
  }

  function handleSearch() {
    navigate({
      pathname: '/search',
      search: `query=${searchDataQuery}`,
    });
    location.reload();
  }

  function handleSortBy(filterType: Filter) {
    if (filterType == Filter.PRICE_LOW_HIGH) {
      setSortByValue('Price: Low to High');
      setFilterType(Filter.PRICE_LOW_HIGH);
    } else if (filterType == Filter.PRICE_HIGH_LOW) {
      setSortByValue('Price: High to Low');
      setFilterType(Filter.PRICE_HIGH_LOW);
    } else {
      setSortByValue('Relevance');
      setFilterType(Filter.RELEVANCE);
    }
  }

  return (
    <>
      <Header></Header>
      <div className="search-results-container">
        <div className="container">
          <div className="search-and-sort">
            <div className="small-search">
              <div className="input-group mb-3">
                <span className="input-group-text" onClick={handleSearch}>
                  <SearchIconSVG />
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={searchDataQuery}
                  onChange={(e) => setSearchDataQuery(e.target.value)}
                  onKeyPress={(e) => handleSearchInputEvent(e.key)}
                />
                <span
                  className="input-group-text"
                  onClick={() => setSearchDataQuery('')}
                >
                  <XSVG />
                </span>
              </div>
            </div>
            <div className="sort-menu">
              <div className="dropdown">
                <div
                  className="niche-header-button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sort By <strong> {sortByValue}</strong> <DownChevronSVG />
                </div>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleSortBy(Filter.PRICE_HIGH_LOW)}
                    >
                      Price: High to Low
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleSortBy(Filter.PRICE_LOW_HIGH)}
                    >
                      Price: Low to High
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleSortBy(Filter.RELEVANCE)}
                    >
                      Relevance
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <SearchResultsGrid
            searchParams={searchParams.get('query') || ''}
            filterType={filterType}
          />
        </div>
      </div>
    </>
  );
}

export default SearchResults;
