import { User } from '@auth0/auth0-react';
import avatar from 'animal-avatar-generator';
import { getUserData, postNewUserData } from './Services/userServices';

export async function updateUserData(authUser: User): Promise<void> {
  const { status } = await getUserData(authUser.sub || '');

  if (status == 204) {
    console.log(
      'Posting ...' + { userEmail: authUser.email, userId: authUser.sub }
    );
    postNewUserData({ email: authUser.email, userId: authUser.sub });
  }
}

export function getSVGFromAvatarSeed(seed: string): string {
  const svgURl = new Blob([avatar(seed)], {
    type: 'image/svg+xml',
  });
  return URL.createObjectURL(svgURl);
}
