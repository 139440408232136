import React, { useState, useEffect } from 'react';
import './setAvailabilityModal.scss';

import { ReactComponent as TrashCanSVG } from '../../Assets/SVGs/trash-can.svg';
import { ReactComponent as PlusSymbolSVG } from '../../Assets/SVGs/plus-symbol.svg';
import { ReactComponent as CardOverlapSVG } from '../../Assets/SVGs/card-overlap.svg';
import { ReactComponent as ErroIconOutlineSVG } from '../../Assets/SVGs/error-icon-outline.svg';
import { ExpertAvailabilityPost } from '../../Types/profilePageTypes';
import { useExpertAvailability } from '../../Hooks/expertProfilePageHooks';
import ReactTooltip from 'react-tooltip';
import { saveExpertAvailability } from '../../Services/expertServices';

type AvailabilityObj = {
  day: string;
  isAvailable: boolean;
  times: AvailableTimes;
}[];

type AvailableTimes = {
  start: string;
  end: string;
}[];

type Props = {
  userName: string;
};

function SetAvailabilityModal(props: Props): JSX.Element {
  // Default availability - this will need to be populated from API
  const { isLoading, data: availabilityData } = useExpertAvailability(
    props.userName
  );
  console.log('availabilityData: ', availabilityData);

  const availability = [
    { day: 'SUN', isAvailable: false, times: [{ start: '', end: '' }] },
    { day: 'MON', isAvailable: false, times: [{ start: '', end: '' }] },
    { day: 'TUE', isAvailable: false, times: [{ start: '', end: '' }] },
    { day: 'WED', isAvailable: false, times: [{ start: '', end: '' }] },
    { day: 'THU', isAvailable: false, times: [{ start: '', end: '' }] },
    { day: 'FRI', isAvailable: false, times: [{ start: '', end: '' }] },
    { day: 'SAT', isAvailable: false, times: [{ start: '', end: '' }] },
  ];

  const [availabilityObj, setAvailabilityObj] =
    useState<AvailabilityObj>(availability);

  // function that takes a time string from API and returns a time array
  function parseTimeString(
    timeString: string
  ): { start: string; end: string }[] {
    if (timeString === '') {
      return [{ start: '', end: '' }];
    }

    const times = [];
    const timeRanges = timeString.split('|');
    for (const timeRange of timeRanges) {
      const start = timeRange.split(',')[0];
      const end = timeRange.split(',')[1];
      times.push({ start: start, end: end });
    }
    return times;
  }

  // Function that takes a timestring from API and Day to create a day obj
  function createDayObject(timeString: string, day: string) {
    const times = parseTimeString(timeString);
    let isAvailable = true;
    if (times[0].start === '') {
      isAvailable = false;
    }
    return { day: day, isAvailable: isAvailable, times: times };
  }
  // Map incoming availability data to iterable object
  useEffect(() => {
    if (availabilityData) {
      const availability = [];
      availability.push(createDayObject(availabilityData.sundayTimes, 'SUN'));
      availability.push(createDayObject(availabilityData.mondayTimes, 'MON'));
      availability.push(createDayObject(availabilityData.tuesdayTimes, 'TUE'));
      availability.push(
        createDayObject(availabilityData.wednesdayTimes, 'WED')
      );
      availability.push(createDayObject(availabilityData.thursdayTimes, 'THU'));
      availability.push(createDayObject(availabilityData.fridayTimes, 'FRI'));
      availability.push(createDayObject(availabilityData.saturdayTimes, 'SAT'));

      setAvailabilityObj(availability);
      console.log('new availability obj: ', availability);
    }
  }, [isLoading]);

  // State variable that monitors validity of times entered.
  const [timeStatus, setTimeStatus] = useState<boolean>(false);
  const [modalEdited, setModalEdited] = useState<boolean>(false);
  const [timeAddResponse, setTimeAddResponse] = useState<string>('');

  //   When user clicks checkbox
  function handleDayCheck(dayIndex: number): void {
    const newAvailabilityObj = [...availabilityObj];
    newAvailabilityObj[dayIndex].isAvailable =
      !availabilityObj[dayIndex].isAvailable;
    setAvailabilityObj(newAvailabilityObj);
  }

  //   inputVal -> what user enters, dayIndex -> day of week,
  //   timeIndex -> which time for a day, timeRange -> start or end
  function handleTimeInput(
    inputVal: string,
    dayIndex: number,
    timeIndex: number,
    timeRange: string
  ): void {
    const newAvailabilityObj = [...availabilityObj];
    if (timeRange == 'start') {
      newAvailabilityObj[dayIndex].times[timeIndex].start = inputVal;
    } else {
      newAvailabilityObj[dayIndex].times[timeIndex].end = inputVal;
    }
    setAvailabilityObj(newAvailabilityObj);
  }

  //   When user clicks trash icon to delete time
  function handleDeleteTime(dayIndex: number, timeIndex: number): void {
    console.log(dayIndex, timeIndex);
    const newAvailabilityObj = [...availabilityObj];

    const oldTimes = newAvailabilityObj[dayIndex].times;
    if (oldTimes.length > 1) {
      newAvailabilityObj[dayIndex].times.splice(timeIndex, 1);
    } else {
      handleDayCheck(dayIndex);
    }
    setAvailabilityObj(newAvailabilityObj);
  }

  //   When user clicks side icons to add a new time slot
  function handleAddTime(dayIndex: number): void {
    const newAvailabilityObj = [...availabilityObj];
    let hasEmptyDate = false;
    for (const timeObj of newAvailabilityObj[dayIndex].times) {
      if (timeObj.start == '' || timeObj.end == '') {
        hasEmptyDate = true;
      }
    }
    if (newAvailabilityObj[dayIndex].isAvailable && !hasEmptyDate) {
      newAvailabilityObj[dayIndex].times.push({ start: '', end: '' });
    }
    setAvailabilityObj(newAvailabilityObj);
  }

  // Function that checks if two times are a valid range - non negative and > 30 mins apart
  function isValidTimeRange(start: string, end: string): boolean {
    if (start === '' && end === '') {
      return true;
    }

    const startTotal = timeToNumber(start);
    const endTotal = timeToNumber(end);

    if (endTotal - startTotal >= 30) {
      return true;
    } else {
      return false;
    }
  }

  // Function that creates a numeric representation of a time string
  function timeToNumber(time: string): number {
    // if time is empty, just return 0
    if (time == '') {
      return 0;
    }
    return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
  }

  //   Checks if the availabilityObj contains a valid time and none are overlapping.
  function checkForValidTimes(): boolean {
    for (let dayIndex = 0; dayIndex < availabilityObj.length; dayIndex++) {
      const dayObj = availabilityObj[dayIndex].times;
      for (let timeIndex = 0; timeIndex < dayObj.length; timeIndex++) {
        const timeObj = dayObj[timeIndex];
        const dayStart = timeObj.start;
        const dayEnd = timeObj.end;

        if (
          !isValidTimeRange(dayStart, dayEnd) ||
          checkIfOverlapping(dayStart, dayEnd, timeIndex, dayIndex)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  // Function that checks for overlapping times for a given day
  function checkIfOverlapping(
    startParam: string,
    endParam: string,
    timeIndex: number,
    dayIndex: number
  ): boolean {
    let currentIndex = 0;
    for (const timeObj of availabilityObj[dayIndex].times) {
      if (currentIndex != timeIndex) {
        const dayStart = timeToNumber(timeObj.start); // a
        const dayEnd = timeToNumber(timeObj.end); // b

        const start = timeToNumber(startParam); // c
        const end = timeToNumber(endParam); // d

        // Check for non overlapping cases - only two
        // (a < c && b < c) || (a > d && b > d)
        if (
          !(
            (dayStart < start && dayEnd < end) ||
            (dayStart > end && dayEnd > end)
          )
        ) {
          return true;
        }
      }

      currentIndex += 1;
    }
    return false;
  }

  // Function that maps time obj to time string
  function timeObjToString(timeObj: AvailableTimes) {
    let timeString = '';
    let timeIndex = 0;
    if (timeObj[0].start === '') {
      return '';
    }
    for (const time of timeObj) {
      if (timeIndex != 0) {
        timeString = timeString.concat('|');
      }
      timeString = timeString.concat(time.start);
      timeString = timeString.concat(',');
      timeString = timeString.concat(time.end);

      timeIndex++;
    }
    return timeString;
  }

  // Parse availability obj into API needed format
  function availabilityObjToAPIObj(
    availabilityObj: AvailabilityObj
  ): ExpertAvailabilityPost {
    const apiObj = {
      expertUserName: props.userName,
      fridayTimes: '',
      mondayTimes: '',
      saturdayTimes: '',
      sundayTimes: '',
      thursdayTimes: '',
      tuesdayTimes: '',
      wednesdayTimes: '',
    };

    for (const dayObj of availabilityObj) {
      switch (dayObj.day) {
        case 'SUN':
          apiObj.sundayTimes = timeObjToString(dayObj.times);
          break;
        case 'MON':
          apiObj.mondayTimes = timeObjToString(dayObj.times);
          break;
        case 'TUE':
          apiObj.tuesdayTimes = timeObjToString(dayObj.times);
          break;
        case 'WED':
          apiObj.wednesdayTimes = timeObjToString(dayObj.times);
          break;
        case 'THU':
          apiObj.thursdayTimes = timeObjToString(dayObj.times);
          break;
        case 'FRI':
          apiObj.fridayTimes = timeObjToString(dayObj.times);
          break;
        case 'SAT':
          apiObj.saturdayTimes = timeObjToString(dayObj.times);
          break;
      }
    }

    return apiObj;
  }

  // Function called when user clicks save
  async function handleSaveTime(): Promise<void> {
    document.getElementById('modal-availablitly')?.click();
    setTimeAddResponse('good');
    const apiAvailabilityObj = availabilityObjToAPIObj(availabilityObj);
    const response = await saveExpertAvailability(apiAvailabilityObj);
    if (response?.status == 200) {
      console.log('saved availability');
    } else {
      console.log('error in saved availability');
    }
    setModalEdited(false);
  }

  useEffect(() => {
    console.log('availabilityObj: ', availabilityObj);
    setTimeStatus(checkForValidTimes());
    setModalEdited(true);
  }, [availabilityObj]);

  return (
    <>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="availabilityModal">
              Setting Weekly Availability
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id="modal-availablitly"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-body-text">
              Check a box to indicate that you are available on that day of the
              week, then fill out the time fields for that day. You may change
              your availability whenever you would like. Times will round to
              nearest half hour.
            </div>
            <div className="modal-availability-selection">
              {availabilityObj.map((dayElement, dayIndex) => (
                <div className="day-row" key={dayElement.day}>
                  <div className="day-mark">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={dayElement.isAvailable}
                      value=""
                      id="flexCheckDefault"
                      onChange={() => handleDayCheck(dayIndex)}
                    ></input>
                    <div className="day-mark-day">{dayElement.day}</div>
                  </div>
                  {dayElement.isAvailable ? (
                    <div className="times">
                      {dayElement.times.map((timeElement, timeIndex) => (
                        <div className="time-element" key={timeIndex}>
                          {isValidTimeRange(
                            timeElement.start,
                            timeElement.end
                          ) &&
                          !checkIfOverlapping(
                            timeElement.start,
                            timeElement.end,
                            timeIndex,
                            dayIndex
                          ) ? (
                            <></>
                          ) : (
                            <>
                              <div className="error-icon-display">
                                <ReactTooltip />
                                <span data-tip="Invalid Range. Times must be > 30 mins apart and non overlapping.">
                                  <ErroIconOutlineSVG />
                                </span>
                              </div>
                            </>
                          )}
                          <div className="choose-time">
                            <div className="input-group mb-3">
                              <input
                                className="form-control"
                                type="time"
                                id="appt"
                                name="appt"
                                required
                                onChange={(e) =>
                                  handleTimeInput(
                                    e.target.value,
                                    dayIndex,
                                    timeIndex,
                                    'start'
                                  )
                                }
                                value={timeElement.start}
                              />
                            </div>
                          </div>
                          <div className="time-divider"> -- </div>
                          <div className="choose-time">
                            <div className="input-group mb-3">
                              <input
                                className="form-control"
                                type="time"
                                id="appt"
                                name="appt"
                                required
                                onChange={(e) =>
                                  handleTimeInput(
                                    e.target.value,
                                    dayIndex,
                                    timeIndex,
                                    'end'
                                  )
                                }
                                value={timeElement.end}
                              />
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              handleDeleteTime(dayIndex, timeIndex)
                            }
                            className="delete-time"
                          >
                            <TrashCanSVG />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="unavailable">Unavailable</div>
                    </>
                  )}
                  <div
                    onClick={() => handleAddTime(dayIndex)}
                    className="add-time"
                  >
                    <div className="add-time-symbol">
                      <PlusSymbolSVG />
                    </div>
                    <div className="add-time-symbol">
                      <CardOverlapSVG />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <div className="navigation-buttons">
              <button
                className="btn btn-outline-dark"
                type="button"
                data-bs-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                className={
                  'btn btn-outline-primary' +
                  (timeStatus && modalEdited ? ' ' : ' disabled')
                }
                type="submit"
                onClick={handleSaveTime}
              >
                SAVE
              </button>
            </div>
            {timeAddResponse == 'error' ? (
              <div className="niche-create-error">
                Oops, something went wrong
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SetAvailabilityModal;
