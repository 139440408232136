import React, { ReactElement, useState, useEffect } from 'react';
import './ScheduleExpertModal.scss';
import { Modal } from 'react-bootstrap';
// import { LoadingSpinner } from '../LoadingSpinner';
import { SearchResult } from '../../Types/searchTypes';
import { getSVGFromAvatarSeed } from '../../helpers';
import { useExpertProfileUsername } from '../../Hooks/useExpertProfile';
import { ReactComponent as PhotoDividerSVG } from '../../Assets/SVGs/photo-divider-line.svg';
import { ReactComponent as CircleWarningSVG } from '../../Assets/SVGs/circle-exclamation-mark.svg';

import { sendTwilio } from '../../Services/expertServices';
import { verifyTwilio } from '../../Services/expertServices';
import { TwilioResponses } from '../../Hooks/types';
import FaqDropdown from '../../Components/faqDropdown/faqDropdown';
import { learnerReachOutRequest } from '../../Services/learnerServices';

type Props = {
  showExpertModal: boolean;
  handleModalClose: () => void;
  modalContent: SearchResult | undefined;
  modalPhoto: string;
};

const VALID_PHONENUMBER_LENGTH = 14;

export const ScheduleExpertModal = ({
  showExpertModal,
  handleModalClose,
  modalContent,
  modalPhoto,
}: Props): ReactElement => {
  const [showModal, setShowModal] = useState<boolean>(showExpertModal);
  const [phoneInputValue, setPhoneInputValue] = useState<string>('');
  const [codeInputValue, setCodeInputValue] = useState<string>('');
  const [phoneVerified, setPhoneVerified] = useState<boolean>(false);
  const [verificationAttemped, setVerificationAttempted] =
    useState<boolean>(false);

  const isValidPhoneNumber =
    phoneInputValue.length === VALID_PHONENUMBER_LENGTH;

  const {
    isLoading: isProfileLoading,
    data: expertProfile,
    refetch: refetchProfile,
  } = useExpertProfileUsername(modalContent?.userName || '');

  function onModalClose() {
    setShowModal(false);
    handleModalClose();
  }

  useEffect(() => {
    setShowModal(showExpertModal);
  }, [showExpertModal]);

  const avatarSVG = getSVGFromAvatarSeed(modalContent?.photo || '');

  useEffect(() => {
    refetchProfile();
  }, [showModal]);

  function formatPhoneNumber(value: string): string {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  async function handleSendCode() {
    if (isValidPhoneNumber) {
      await sendTwilio(phoneInputValue);
    } else {
      console.log('Invalid phone number!');
    }
  }

  async function handleVerify() {
    if (isValidPhoneNumber) {
      const resp = await verifyTwilio(phoneInputValue, codeInputValue);
      console.log('resp: ', resp);
      setPhoneVerified(resp.data === TwilioResponses.VALID);
      setVerificationAttempted(true);
    } else {
      console.log('Invalid phone number!');
    }
  }
  console.log('phoneVerified: ', phoneVerified);

  async function handleCodeInput(input: string) {
    // Auto formating phone number input
    setCodeInputValue(input);
  }

  const handlePhoneInput = (input: string) => {
    // Auto formating phone number input
    setPhoneInputValue(formatPhoneNumber(input));

    // If phone value gets changed, they are will need to re-verify
    setPhoneVerified(false);
    setVerificationAttempted(false);
  };

  async function onSendRequest() {
    const resp = await learnerReachOutRequest(
      '1' + expertProfile?.phone.replace(/\D/g, '') || '',
      modalContent?.userName || '',
      '1' + phoneInputValue?.replace(/\D/g, ''),
      modalContent?.name || ''
    );
    if (resp.status == 200) {
      onModalClose();
    } else {
      console.log('request failed');
    }
  }

  return (
    <>
      <div className="schedule-expert-modal-container">
        {isProfileLoading ? (
          <></>
        ) : (
          <>
            <Modal show={showModal} onHide={onModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Connect with this Expert</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <div className="photo-and-description">
                    <div className="modal-expert-photo">
                      {modalContent?.photo &&
                      modalContent?.photo.includes('photoId') ? (
                        <img
                          src={'data:image/png;base64,' + modalPhoto}
                          width="136"
                          height="136"
                          alt=""
                        ></img>
                      ) : (
                        <img src={avatarSVG} />
                      )}
                    </div>
                    <div className="photo-divider-line">
                      <PhotoDividerSVG />
                    </div>
                    <div className="modal-expert-description-username">
                      <div className="modal-expert-username">
                        {modalContent?.userName}
                      </div>
                      <div className="modal-expert-description">
                        {expertProfile?.bio}
                        {/* myString = myString.replace(/\D/g,''); */}
                      </div>
                    </div>
                  </div>
                  <div className="expert-description-tags-title">
                    <div className="expert-title">{modalContent?.name}</div>
                    <div className="expert-tags">
                      {modalContent?.tags[0] === undefined ? (
                        <></>
                      ) : (
                        modalContent?.tags
                          .toString()
                          .split(',')
                          .map((niche_tag, index) => {
                            return (
                              <div key={index} className="niche-content-tag">
                                <div className="niche-content-tag-text">
                                  {niche_tag.toUpperCase()}
                                </div>
                              </div>
                            );
                          })
                      )}
                    </div>
                    <div className="expert-niche-description">
                      {modalContent?.description}
                    </div>
                  </div>
                  <div className="call-information-verify">
                    <div className="call-information">
                      After you verify number, you can send the Expert a request
                      to have a conversation. The Expert will reach out to you
                      once they have recieved and accepted your request.
                    </div>
                    <div className="phone-verify">
                      <input
                        aria-label="phone number input"
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="(___) - ___ - ____"
                        value={phoneInputValue}
                        onChange={(e) => handlePhoneInput(e.target.value)}
                      />
                      <span>
                        <button
                          type="button"
                          className={
                            'btn btn-outline-secondary btn-lg' +
                            (!isValidPhoneNumber ? ' disabled' : '')
                          }
                          onClick={handleSendCode}
                        >
                          Send Code
                        </button>
                      </span>
                    </div>
                    <div className="code-verify">
                      <input
                        aria-label="code"
                        type="text"
                        className="form-control form-control-sm"
                        value={codeInputValue}
                        onChange={(e) => handleCodeInput(e.target.value)}
                      />
                      <span>
                        <button
                          className="btn btn-outline-secondary btn-lg"
                          onClick={handleVerify}
                          disabled={codeInputValue == ''}
                        >
                          Verify
                        </button>
                      </span>
                    </div>
                    <div className="verification-message">
                      {phoneVerified && verificationAttemped ? (
                        <div className="valid-feedback-verify">Looks good!</div>
                      ) : (
                        <></>
                      )}
                      {!phoneVerified && verificationAttemped ? (
                        <div className="invalid-feedback d-block">
                          Verification Unsuccessful. Try Again.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="modal-faq">
                    <FaqDropdown />
                  </div>
                </>
              </Modal.Body>
              <Modal.Footer className="footerCustom">
                <div className="expert-modal-custom-footer">
                  <div className="price-details-price-warning">
                    <div className="price-details">
                      <div className="price-detail">
                        <strong>Price for 30 minutes: </strong> $
                        {modalContent?.flatRate}
                      </div>
                      <div className="price-detail">
                        <strong>Price per minute: </strong> $
                        {modalContent?.dynamicRate}
                      </div>
                    </div>
                    <div className="price-warning">
                      <CircleWarningSVG /> Prices are applied after a successful
                      call.
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-lg "
                    onClick={onSendRequest}
                    disabled={!phoneVerified}
                  >
                    Send Request
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default ScheduleExpertModal;
