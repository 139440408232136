import axios from 'axios';
import { UserProfile, PostUserData } from '../Types/userTypes';
const instance = axios.create({
  baseURL: 'https://wetrieve-apis.herokuapp.com/api/v1/internal',
  timeout: 10000,
});

export type APIReturn<TData> = {
  status: number;
  data?: TData;
  error?: string;
};

/*

Todo: 
  - create hook called useUserProfile

*/

// Get a user's data given the ID
export async function getUserData(
  userID: string
): Promise<APIReturn<UserProfile>> {
  console.log('user data:', userID);
  try {
    const response = await instance.get(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/user/profile',
      {
        headers: {
          userId: userID,
        },
      }
    );
    console.log('getUserData response: ', response);
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    console.log('Error: getUserData');
    return {
      status: 204,
      error: 'Error: getUserData',
    };
  }
}

// When a new user logs in, post their ID to sync our DB
export async function postNewUserData(
  data: PostUserData
): Promise<APIReturn<PostUserData>> {
  console.log('user post data:', data);
  try {
    const response = await instance.post(
      'https://wetrieve-apis.herokuapp.com/api/v1/internal/user/onboard',
      { email: data.email, userId: data.userId }
    );
    console.log(response);
    // return response;
    return {
      status: response.status,
      data: response.data,
      error: '',
    };
  } catch (error: unknown) {
    return {
      status: 204,
      error: 'Error: getUserData',
    };
  }
}
