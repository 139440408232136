import React from 'react';
import './aboutPage.scss';

function AboutPage() {
  return (
    <>
      <div className="about-container">About Page...</div>
    </>
  );
}

export default AboutPage;
