import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ExpertOnboardingSetters } from '../../../Widgets/ExpertOnboarding/ExpertOnboardingWidget';
import './expertOnboardingBio.scss';

function ExpertOnboardingBio() {
  const { setIsStepComplete, setStepInput } =
    useOutletContext<ExpertOnboardingSetters>();
  // Form checker -------
  const [sectionCompleteStatus, setSectionCompleteStatus] =
    useState<boolean>(false);

  function updateSectionStatus(): void {
    if (bioCharCount != 0) {
      setSectionCompleteStatus(true);
    } else {
      setSectionCompleteStatus(false);
    }
  }

  const [bioCharCount, setBioCharCount] = useState<number>(0);
  useEffect(() => {
    // Update the document title using the browser API
    const bioCountVal: number = bioCharCount;
    setBioCharCount(bioCountVal);

    updateSectionStatus();
  }, [bioCharCount]);

  // Bio Input Handling
  const [bio, setBio] = useState<string>('');
  function handleBioInput(text: string): void {
    setBioCharCount(text.length);
    setBio(text);
  }

  useEffect(() => {
    setStepInput(bio);
    setIsStepComplete(sectionCompleteStatus);
  }, [bio, sectionCompleteStatus]);

  return (
    <>
      <div className="personal-info-wrapper">
        <div className="header-section">
          <div className="header-text">Your Bio</div>
        </div>
        <div className="section-description">
          A short description of who you are and your expertise. Your bio is a
          great way to communicate to others what your backround is and why you
          are an expert in some areas.
        </div>
        <div className="bio">
          <textarea
            rows={8}
            maxLength={280}
            className="form-control form-control-lg"
            placeholder="Describe who you are!"
            onChange={(e) => handleBioInput(e.target.value)}
          ></textarea>
          <div className="bio-character-count">
            <span>{bioCharCount}</span> / 280 characters
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpertOnboardingBio;
