import { useEffect, useState } from 'react';
import axios, { AxiosRequestHeaders } from 'axios';
import { RestMethod } from './types';
import { APIReturn } from '../Types/APITypes';

export type APIHookReturn<T> = APIReturn<T> & {
  isLoading: boolean;
  refetch: () => void;
};

type Props = {
  method: RestMethod;
  url: string;
  body?: object;
  headers?: AxiosRequestHeaders;
};

/*

Todo:

- replace axios with 'fetch' because axios can't handle images well


*/

const useAPI = <T,>({
  method,
  url,
  body,
  headers,
}: Props): APIHookReturn<T> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<T>();
  const [status, setStatus] = useState<number>(400);
  const [error, setError] = useState<string>();

  async function fetchData() {
    try {
      setIsLoading(true);
      const resp = await axios({
        method: method,
        url: url,
        headers: headers,
        data: body,
      });
      setStatus(resp.status);
      setData(resp.data);
    } catch (error: unknown) {
      setError(error as string);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []); // empty callback array keeps fetchData from being called infinitely

  return { isLoading, data, error, status, refetch: fetchData };
};

export default useAPI;
