import useAPI from './useAPI';
import { SearchResult } from '../Types/searchTypes';
import { APIHookReturn } from './useAPI';

const GET_SEARCH_URL =
  'https://wetrieve-apis.herokuapp.com/api/v1/internal/search/';

enum Filter {
  RELEVANCE = 'RELEVANCE',
  PRICE_LOW_HIGH = 'PRICE_LOW_HIGH',
  PRICE_HIGH_LOW = 'PRICE_HIGH_LOW',
}
export type GetHookMultiInput<TData> = (
  arg0: string,
  arg1: Filter
) => APIHookReturn<TData>;

// Profile Niche Info
const useSearchResults: GetHookMultiInput<SearchResult[]> = (
  search: string,
  filter: Filter
) => {
  return useAPI<SearchResult[]>({
    method: 'get',
    url: GET_SEARCH_URL,
    headers: { search: search, filter: filter },
  });
};

export { useSearchResults };
