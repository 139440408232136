import React, { ReactElement } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

// Route protection component.
// Used to protect routes that require a login
// Once logged in, the redirectURL navigates them to their desired page.

type RequireAuthProps = {
  children: ReactElement;
};

function RequireAuth({ children }: RequireAuthProps) {
  return children;
}

export default withAuthenticationRequired(RequireAuth, {
  onRedirecting: () => <p>AUTHENTICATING USER...</p>,
  returnTo: '/', // todo: make redirect to the page the user was on
});
