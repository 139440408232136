import React, { useState, useEffect, ReactElement } from 'react';
import './expertOnboardingPhoneNumber.scss';
import { useOutletContext } from 'react-router-dom';
import { ExpertOnboardingSetters } from '../../../Widgets/ExpertOnboarding/ExpertOnboardingWidget';
import { sendTwilio } from '../../../Services/expertServices';
import VerificationModal from './VerificationModal';

const MODAL_ID = 'phoneVerifyModal';
const VALID_PHONENUMBER_LENGTH = 14; // 10 numbers + 2 parantheses + 1 space + 1 dash

function ExpertOnboardingPhoneNumber(): ReactElement {
  const { setIsStepComplete, setStepInput } =
    useOutletContext<ExpertOnboardingSetters>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneInputValue, setPhoneInputValue] = useState<string>('');
  const [phoneVerified, setPhoneVerified] = useState<boolean>(false);
  const [verificationAttemped, setVerificationAttempted] =
    useState<boolean>(false);

  const isValidPhoneNumber =
    phoneInputValue.length === VALID_PHONENUMBER_LENGTH;

  const handlePhoneInput = (input: string) => {
    // Auto formating phone number input
    setPhoneInputValue(formatPhoneNumber(input));

    // If phone value gets changed, they are will need to re-verify
    setPhoneVerified(false);
    setVerificationAttempted(false);
  };

  function formatPhoneNumber(value: string): string {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  async function handleVerify() {
    if (isValidPhoneNumber) {
      await sendTwilio(phoneInputValue);
    } else {
      console.log('Invalid phone number!');
    }
  }

  useEffect(() => {
    setStepInput(phoneInputValue);
    setIsStepComplete(phoneVerified);
  }, [phoneVerified, phoneInputValue]);

  return (
    <>
      {isLoading ? (
        <p>Verifying Phone Number...</p>
      ) : (
        <div className="personal-info-wrapper">
          <div className="header-section">
            <div className="header-text">Phone Number</div>
          </div>
          <div className="section-description">
            A real number is required to provide your services to others. We
            will send you a 6 digit code to verify your number.
          </div>
          <VerificationModal
            setIsVerified={setPhoneVerified}
            setVerificationAttempted={setVerificationAttempted}
            setIsLoading={setIsLoading}
            modalId={MODAL_ID}
            phoneNumber={phoneInputValue}
          />
          <div className="phone-number">
            <div>
              <label className="form-label">Phone Number*</label>
            </div>
            <div className="phone-number-verify">
              <input
                aria-label="phone number input"
                type="text"
                className="form-control form-control-lg"
                placeholder="(___) - ___ - ____"
                value={phoneInputValue}
                onChange={(e) => handlePhoneInput(e.target.value)}
              />
              <span>
                <button
                  type="button"
                  className={
                    'btn btn-outline-primary' +
                    (!isValidPhoneNumber ? ' disabled' : '')
                  }
                  data-bs-toggle="modal"
                  data-bs-target={`#${MODAL_ID}`}
                  onClick={handleVerify}
                >
                  Verify
                </button>
              </span>
            </div>
            {phoneVerified && <div className="valid-feedback">Looks good!</div>}
            {!phoneVerified && verificationAttemped && (
              <div className="invalid-feedback d-block">
                Verification Unsuccessful. Try Again.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ExpertOnboardingPhoneNumber;
